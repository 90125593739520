import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useResales() {
  const [ resales, setResales ] = useState<Resale[]>()
  useEffect(() => {
    data.get<Resale[]>('/resales')
      .then(response => response.body)
      .then(resales => setResales(resales!))
  }, [])
  return resales
}

export function useResale(key: ResaleKey) {
  const [ resale, setResale ] = useState<Resale>()
  useEffect(() => {
    if (key && ('register_number' in key || 'id' in key)) {
      const search = 'register_number' in key ? `register_number/${key.register_number}` : key.id

      data.get<Resale>(`/viewresale/${search}`)
        .then(response => response.body)
        .then(resale => setResale(resale!))
    }
  }, [key])
  return resale
}