export function getEndpoint() {
  if (process.env.REACT_APP_API_NODE_ENV === "development") {
    return process.env.REACT_APP_API_ENDPOINT_DEV;
  }
  if (process.env.REACT_APP_API_NODE_ENV === "staging") {
    return process.env.REACT_APP_API_ENDPOINT_STAGING;
  }
  if (process.env.REACT_APP_API_NODE_ENV === "production") {
    return process.env.REACT_APP_API_ENDPOINT_PROD;
  }
  if (process.env.REACT_APP_API_NODE_ENV === "poc") {
    return process.env.REACT_APP_API_ENDPOINT_POC;
  }
  //  return 'http://localhost:5555'
  return "http://localhost:3333";
}
