export enum SalesPositions {
  Financial = 1,
  PreSales = 2,
  Commercial = 3
}

export const ESalesPositions = new Map<SalesPositions, string>([
  [SalesPositions.Financial, 'Financeiro'],
  [SalesPositions.PreSales, 'Pré-venda'],
  [SalesPositions.Commercial, 'Comercial']
])