import React, { FC, useEffect, useState } from "react";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ptBR } from "date-fns/locale";
import styles from "./Styles.module.scss";

export interface ISearch {
  id: string;
  filterValue: (a: IFilterDate) => void;
  filterValueClean: () => void;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

export interface IFilterDate {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const FilterDate: FC<ISearch> = ({
  id,
  filterValue,
  filterValueClean,
  defaultStartDate,
  defaultEndDate,
}) => {
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (date) => {
    setSelectedDate(date.selection);
    console.log("handleSelect", date.selection);
  };

  const handleFilter = () => {
    filterValue(selectedDate);
  };

  const handleFilterClosure = () => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    console.log("handleFilterClosure", data);
    setSelectedDate(data);
    filterValueClean();
  };

  useEffect(() => {
    if (defaultStartDate && defaultEndDate) {
      const startData = new Date(defaultStartDate);
      const endData = new Date(defaultEndDate);

      const data = {
        startDate: new Date(startData.getTime() + 86400000),
        endDate: new Date(endData.getTime() + 86400000),
        key: "selection",
      };
      setSelectedDate(data);
    }
  }, [defaultEndDate, defaultStartDate]);

  return (
    <div className={styles.FilterList}>
      <DateRange
        className={styles.Item}
        locale={ptBR}
        weekdayDisplayFormat="EEEEEE"
        ranges={[selectedDate]}
        onChange={handleSelect}
        fixedHeight={true}
        dateFormat="EEEE, dd/MM/yyyy"
      />
      <div className={styles.FilterButtons}>
        <button
          className={styles.FilterButton}
          style={{ backgroundColor: "gray" }}
          onClick={handleFilterClosure}
        >
          Limpar
        </button>
        <button className={styles.FilterButton} onClick={handleFilter}>
          Aplicar
        </button>
      </div>
    </div>
  );
};
