import React, { FC } from "react";

import { useAuth } from "../../shared/auth";
import { UserType } from "../../shared/data/enums";
import { MainLayout } from "pages/components/MainLayout";
import { Resale } from "./content/Resale";

function getComponent(type: UserType) {
  switch (type) {
    case UserType.Resale:
      return Resale;
    default:
      return () => <div />;
  }
}

export const Dashboard: FC = () => {
  const auth = useAuth();
  const Content = getComponent(auth.user.userType);
  return (
    <MainLayout title="Dashboard">
      <Content />
    </MainLayout>
  );
};
