import React, { FC, createRef, useState } from "react";

import { useAuth, usePermission } from "../../shared/auth";

import { useContacts } from "../../shared/data";
import { EResaleCategory, ERevenueCategories } from "../../shared/data/enums";
import { Form } from "../../shared/form/Form";
import { FormCheck } from "../../shared/form/FormCheck";
import { FormCheckGroup } from "../../shared/form/FormCheckGroup";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormRadio } from "../../shared/form/FormRadio";
import { FormRadioGroup } from "../../shared/form/FormRadioGroup";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import { Button } from "../../shared/layout/bootstrap/button/Button";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";
import { classNames } from "../../shared/util/class-names";
import { formatPhone } from "../../shared/util/format";

import { MainLayout } from "pages/components/MainLayout";

import classes from "./Profile.module.scss";

const states = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const Profile: FC = () => {
  const auth = useAuth();
  const contacts = useContacts();
  const addContactPermission = usePermission("contacts", "add");
  const extra = createRef<HTMLDivElement>();
  const [showExtra, setShowExtra] = useState(false);
  return (
    <MainLayout title="Meu perfil">
      {auth.resale && (
        <Card>
          <CardBody>
            <Form readOnly value={auth.resale}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup label="CNPJ">
                    <FormInput name="cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup label="Inscrição Municipal">
                    <FormInput name="municipalRegistration" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup label="Inscrição Estadual">
                    <FormInput name="stateRegistration" />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <div
                    className={classNames(
                      "row",
                      classes.extra,
                      showExtra && classes.show
                    )}
                    ref={extra}
                  >
                    <div className="col-12 mt-2">
                      <h6 className="text-primary mb-3">ENDEREÇO</h6>
                    </div>
                    <div className="col-md-2">
                      <FormGroup label="CEP">
                        <FormInput name="cep" />
                      </FormGroup>
                    </div>
                    <div className="col-md-5">
                      <FormGroup label="Rua">
                        <FormInput name="street" />
                      </FormGroup>
                    </div>
                    <div className="col-md-2">
                      <FormGroup label="Número">
                        <FormInput name="number" />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup label="Complemento">
                        <FormInput name="complement" />
                      </FormGroup>
                    </div>
                    <div className="col-md-5">
                      <FormGroup label="Bairro">
                        <FormInput name="neighborhood" />
                      </FormGroup>
                    </div>
                    <div className="col-md-5">
                      <FormGroup label="Cidade">
                        <FormInput name="city" />
                      </FormGroup>
                    </div>
                    <div className="col-md-2">
                      <FormGroup label="UF">
                        <FormInput name="state" />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <h6 className="text-primary mb-3">ATUAÇÃO</h6>
                    </div>
                    <div className="col-12">
                      <FormGroup label="Ramo de Atuação">
                        <div className="row">
                          <FormCheckGroup name="lineOfBusiness">
                            <div className="col-md py-2">
                              <FormCheck value="Consultoria">
                                Consultoria
                              </FormCheck>
                            </div>
                            <div className="col-md py-2">
                              <FormCheck value="Tecnologia">
                                Tecnologia
                              </FormCheck>
                            </div>
                            <div className="col-md py-2">
                              <FormCheck value="Jurídico">Jurídico</FormCheck>
                            </div>
                            <div className="col-md py-2">
                              <FormCheck value="Compliance">
                                Compliance
                              </FormCheck>
                            </div>
                            <div className="col-md py-2">
                              <FormCheck value="Segurança de TI">
                                Segurança de TI
                              </FormCheck>
                            </div>
                          </FormCheckGroup>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup label="Faturamento">
                        <div className="row">
                          <FormRadioGroup name="revenue">
                            {Array.from(ERevenueCategories.entries()).map(
                              ([value, label]) => (
                                <div key={value} className="col-md">
                                  <FormRadio value={value}>{label}</FormRadio>
                                </div>
                              )
                            )}
                          </FormRadioGroup>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup label="Filiais">
                        <FormInput name="numberBranches" />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup label="Quantidade Funcionários">
                        <FormInput name="numberEmployees" />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup label="Distribuição de vendas atual">
                        <div className="row">
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Educação
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="currentSalesDistribution.0.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Governo
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="currentSalesDistribution.1.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">Varejo</div>
                              <div className="col-6">
                                <FormInput
                                  name="currentSalesDistribution.2.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Indústria
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="currentSalesDistribution.3.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Energia
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="currentSalesDistribution.4.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup label="Distribuição por tipos de venda">
                        <div className="row">
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Serviços
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="salesTypeDistribution.0.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Hardware
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="salesTypeDistribution.1.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Assinatura
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="salesTypeDistribution.2.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">
                                Licenças
                              </div>
                              <div className="col-6">
                                <FormInput
                                  name="salesTypeDistribution.3.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 py-2">
                            <div className="row">
                              <div className="col-6 col-form-label">Outros</div>
                              <div className="col-6">
                                <FormInput
                                  name="salesTypeDistribution.4.value"
                                  mask="percent"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <h6 className="col-12">Região de Cobertura</h6>
                    <FormCheckGroup name="coverageRegions">
                      {Object.entries(states).map(([value, label]) => (
                        <div key={value} className="col-sm-4 py-2">
                          <FormCheck name="name" value={value}>
                            {label} ({value})
                          </FormCheck>
                        </div>
                      ))}
                    </FormCheckGroup>
                    <div className="col-12">
                      <FormGroup label="Categoria solicitada para aplicação">
                        <div className="row">
                          <FormRadioGroup name="resaleCategory">
                            {Array.from(EResaleCategory.entries()).map(
                              ([value, label]) => (
                                <div key={value} className="col-md py-2">
                                  <FormRadio value={value}>{label}</FormRadio>
                                </div>
                              )
                            )}
                          </FormRadioGroup>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-12 mt-2">
                      <h6 className="text-primary mb-3">
                        DADOS DO CONTATO PRINCIPAL DA REVENDA
                      </h6>
                    </div>
                    <div className="col-md-4">
                      <FormGroup label="Nome">
                        <FormInput name="name" />
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup label="Telefone">
                        <FormInput name="phoneNumber" mask="phone" />
                      </FormGroup>
                    </div>
                    <div className="col-md-5">
                      <FormGroup label="E-mail">
                        <FormInput name="email" />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => setShowExtra((show) => !show)}
                >
                  {showExtra ? "Esconder" : "Mostrar mais"}
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
      {contacts && (
        <div>
          <div className="container mt-4 mb-3">
            <div className="d-flex align-items-center">
              <h4 className="text-primary m-0">CONTATOS</h4>
              <div className="ml-auto">
                {addContactPermission && (
                  <Button to="/contacts/add" variant="primary">
                    Cadastrar Contato
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Table hover>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                  <TableCell>{formatPhone(contact.phoneNumber)}</TableCell>
                  <TableCell>
                    <IconButton to={`/contacts/view/${contact.id}`}>
                      <IconEyeFill />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </MainLayout>
  );
};
