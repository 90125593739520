import React, { FC } from 'react'

import { classNames } from '../../../util/class-names'

interface CardBodyProps {
  padding?: boolean
}

export const CardBody: FC<CardBodyProps> = ({children, padding}) => (
  <div className={classNames('card-body', padding === false && 'p-0')}>
    {children}
  </div>
)
