import { FC, useLayoutEffect } from 'react'

import { useAuth } from '../shared/auth';

export const Logout: FC = () => {
  const auth = useAuth()
  useLayoutEffect(() => {
    auth.logout()
  })
  return null
}
