import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useClients() {
  const [ clients, setClients ] = useState<Client[]>()
  useEffect(() => {
    data.get<Client[]>('/api/clients')
      .then(response => response.body)
      .then(clients => setClients(clients!))
  }, [])
  return clients
}

export function useClient(id: string) {
  const [ client, setClient ] = useState<Client>()
  useEffect(() => {
    data.get<Client>(`/api/clients/${id}`)
      .then(response => response.body)
      .then(client => setClient(client!))
  }, [id])
  return client
}
