import { useAuth } from '../AuthContext'

const permissions = {
  clients: {
    view: ['commercialManager', 'contact', 'resale']
  },
  commissions: {
    view: ['resale']
  },
  contacts: {
    add: ['resale'],
    edit: ['resale'],
    view: ['resale']
  },
  leads: {
    add: ['contact', 'resale'],
    edit: ['accountManager', 'commercialManager'],
    view: ['accountManager', 'commercialManager', 'contact', 'resale']
  },
  opportunities: {
    edit: ['accountManager', 'commercialManager'],
    order: ['contact', 'resale'],
    view: ['accountManager', 'commercialManager', 'contact', 'resale']
  },
  profile: {
    view: ['contact', 'resale']
  },
  resales: {
    add: [],
    edit: ['channelManager'],
    view: ['channelManager']
  },
  subscriptions: {
    view: ['commercialManager', 'contact', 'resale']
  }
}

export function usePermission(scope: keyof typeof permissions, action: string = 'view') {
  const auth = useAuth()
  const roles = permissions[scope][action]
  return roles.includes(auth?.user?.userType)
}
