import React, {
  Children,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  createContext,
} from "react";

export type FormValue = null | string | number | boolean | FormValue[];
export type FormObject = { [key: string]: FormValue | FormDataValue };
export type FormDataValue = FormValue | FormObject;

interface FormContextInterface {
  get?: (prop: string) => FormValue | FormDataValue;
  set?: (prop: string, value: FormValue | FormDataValue, mask?: string) => void;
  readOnly?: boolean;
}

interface FormContextProviderProps {
  onChange?: (_: FormDataValue) => void;
  onSubmit?: (_: FormDataValue) => any;
  readOnly?: boolean;
  target?: string;
  value?: FormDataValue;
}

export const FormContext = createContext<FormContextInterface>({});

export function useFormContext() {
  return useContext(FormContext);
}

const FormLoadingContext = createContext<boolean>(false);

export function useFormLoading() {
  return useContext(FormLoadingContext);
}

export const FormContextProvider: FC<FormContextProviderProps> = ({
  children,
  onChange,
  onSubmit,
  value,
  ...props
}) => {
  const [current, setCurrent] = useState<FormDataValue>(value || {});
  const [loading, setLoading] = useState<boolean>(false);
  const mounted = useRef(true);
  const form: FormContextInterface = {
    get: (prop) => {
      if (prop) {
        const [target, ...path] = prop.split(".").reverse();
        const data = path
          .reverse()
          .reduce(
            (current, path) => (current[path] || {}) as FormDataValue,
            current
          );
        return data[target];
      }
    },
    set: (prop, value) => {
      const path = prop.split(".");
      setCurrent((current) => {
        const data = Object.assign({}, current);
        const target = path.slice(0, -1).reduce((current, path, i, target) => {
          if (i < target.length) {
            if (Number.isInteger(parseInt(path))) {
              return (current[path] = current[path] || []) as FormDataValue;
            } else {
              return (current[path] = current[path] || {}) as FormDataValue;
            }
          } else {
            return data;
          }
        }, data);
        target[path[path.length - 1]] = value;
        return data;
      });
    },
    ...props,
  };
  useEffect(() => {
    if (value && value !== current) {
      setCurrent((current: FormObject) =>
        Object.assign({}, current, value as FormObject)
      );
    }
  }, [current, value]);
  useEffect(() => {
    if (current && current !== value) {
      onChange && onChange(current);
    }
  }, [current, onChange, value]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const handleSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    onSubmit &&
      onSubmit(current)
        .then(() => mounted.current && setLoading(false))
        .catch(() => mounted.current && setLoading(false));
  };
  const child = Children.only(children) as React.ReactElement;
  return (
    <FormContext.Provider value={form}>
      <FormLoadingContext.Provider value={loading}>
        {React.cloneElement(child, { onSubmit: handleSubmit })}
      </FormLoadingContext.Provider>
    </FormContext.Provider>
  );
};
