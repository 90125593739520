import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { data } from "../../shared/data";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card, CardBody } from "../../shared/layout/bootstrap/card";

import { MainLayout } from "pages/components/MainLayout";

export const ContactAdd: FC = () => {
  const history = useHistory();
  const handleSubmit: FormCallback = (value) => {
    return data
      .post("/api/contacts", value)
      .then(() => history.push("/contacts"));
  };
  return (
    <MainLayout title="Cadastrar contato" arrowBack>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <FormGroup label="Nome">
                  <FormInput name="name" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup label="Telefone">
                  <FormInput name="phoneNumber" mask="phone" />
                </FormGroup>
              </div>
              <div className="col-md-8">
                <FormGroup label="E-mail">
                  <FormInput name="email" />
                </FormGroup>
              </div>
            </div>
            <div className="text-right">
              <FormSubmit>Cadastrar</FormSubmit>
            </div>
          </Form>
        </CardBody>
      </Card>
    </MainLayout>
  );
};
