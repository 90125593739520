import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useOrders() {
  const [ orders, setOrders ] = useState<Order[]>()
  useEffect(() => {
    data.get<Order[]>('/api/orders')
      .then(response => response.body)
      .then(orders => setOrders(orders!))
  }, [])
  return orders
}

export function useOrder(id: string) {
  const [ order, setOrder ] = useState<Order>()
  useEffect(() => {
    data.get<Order>(`/api/orders/${id}`)
      .then(response => response.body)
      .then(order => setOrder(order!))
  }, [id])
  return order
}
