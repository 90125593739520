import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footbar: React.FC = () => (
  <div id={styles.FootbarDiv}>
    <div className={styles.footerIcon} id={styles.logo}>
      <div className={styles.footerHeader}>
        <Link className="navbar-brand" to="/">
          <img
            className={styles.logo}
            src="/img/dnasec.png"
            alt="DNASec Logo"
          />
        </Link>
      </div>
    </div>
    <div className={styles.footerIcon}>
      <div className={styles.footerHeader}>
        <h5>Sobre</h5>
      </div>
      <div className={styles.footerItems}>
        <a target="_blank" href="#/contact">
          Entre em Contato
        </a>
        <a target="_blank" href="/#/privacypolicy">
          Política de Privacidade
        </a>
      </div>
    </div>

    <div className={styles.footerIcon} id={styles.socialMedia}>
      <div className={styles.footerHeader}>
        <h5>Redes Sociais</h5>
      </div>
      <div className={styles.footerSocial}>
        <a target="_blank" href="https://www.facebook.com/box.group10">
          <FacebookIcon fontSize="large" />
        </a>
        <a target="_blank" href="https://br.linkedin.com/company/boxgroup2010">
          <LinkedInIcon fontSize="large" />
        </a>
        <a target="_blank" href="https://www.instagram.com/box_group/">
          <InstagramIcon fontSize="large" />
        </a>
      </div>
    </div>
  </div>
);

export default Footbar;
