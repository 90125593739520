import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { data } from "../../shared/data";
import { EResaleCategory, ERevenueCategories } from "../../shared/data/enums";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormCheck } from "../../shared/form/FormCheck";
import { FormCheckGroup } from "../../shared/form/FormCheckGroup";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormRadio } from "../../shared/form/FormRadio";
import { FormRadioGroup } from "../../shared/form/FormRadioGroup";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import { MainLayout } from "pages/components/MainLayout";

const states = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const ResaleAdd: FC = () => {
  const history = useHistory();
  const handleSubmit: FormCallback = (value) => {
    return data
      .post("/api/resales", value)
      .then(() => history.push("/resales"))
      .catch(() => alert("Falha ao cadastrar revenda!"));
  };
  return (
    <MainLayout title="Cadastrar Revenda">
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <FormGroup label="Razão social">
                  <FormInput name="companyName" />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Nome fantasia">
                  <FormInput name="tradingName" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup label="CNPJ">
                  <FormInput name="cnpj" mask="cnpj" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup label="Inscrição Municipal">
                  <FormInput name="municipalRegistration" />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup label="Inscrição Estadual">
                  <FormInput name="stateRegistration" />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup label="Logotipo">
                  <FormInput name="logoUrl" />
                </FormGroup>
              </div>
              <div className="col-12 mt-2">
                <h6 className="text-primary mb-3">ENDEREÇO</h6>
              </div>
              <div className="col-md-2">
                <FormGroup label="CEP">
                  <FormInput name="cep" />
                </FormGroup>
              </div>
              <div className="col-md-5">
                <FormGroup label="Rua">
                  <FormInput name="street" />
                </FormGroup>
              </div>
              <div className="col-md-2">
                <FormGroup label="Número">
                  <FormInput name="number" />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup label="Complemento">
                  <FormInput name="complement" />
                </FormGroup>
              </div>
              <div className="col-md-5">
                <FormGroup label="Bairro">
                  <FormInput name="neighborhood" />
                </FormGroup>
              </div>
              <div className="col-md-5">
                <FormGroup label="Cidade">
                  <FormInput name="city" />
                </FormGroup>
              </div>
              <div className="col-md-2">
                <FormGroup label="UF">
                  <FormInput name="state" />
                </FormGroup>
              </div>
              <div className="col-12">
                <h6 className="text-primary mb-3">ATUAÇÃO</h6>
              </div>
              <div className="col-12">
                <FormGroup label="Ramo de Atuação">
                  <FormInput name="lineOfBusiness" />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup label="Faturamento">
                  <FormRadioGroup name="revenue">
                    {Array.from(ERevenueCategories.entries()).map(
                      ([value, label]) => (
                        <FormRadio key={value} value={value} inline>
                          {label}
                        </FormRadio>
                      )
                    )}
                  </FormRadioGroup>
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup label="Filiais">
                  <FormInput name="numberBranches" />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup label="Quantidade Funcionários">
                  <FormInput name="numberEmployees" />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup label="Distribuição de vendas atual">
                  <FormInput name="currentSalesDistribution" />
                </FormGroup>
              </div>
              <div className="col-12">
                <FormGroup label="Distribuição por tipos de venda">
                  <FormInput name="salesTypeDistribution" />
                </FormGroup>
              </div>
              <h6 className="col-12">Região de Cobertura</h6>
              <FormCheckGroup name="coverageRegions">
                {Object.entries(states).map(([value, label]) => (
                  <div key={value} className="col-sm-4 py-2">
                    <FormCheck name="name" value={value}>
                      {label} ({value})
                    </FormCheck>
                  </div>
                ))}
              </FormCheckGroup>
              <div className="col-12">
                <FormGroup label="Categoria de Revenda">
                  <FormRadioGroup name="resaleCategory">
                    {Array.from(EResaleCategory.entries()).map(
                      ([value, label]) => (
                        <FormRadio key={value} value={value} inline>
                          {label}
                        </FormRadio>
                      )
                    )}
                  </FormRadioGroup>
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup label="Nome">
                  <FormInput name="name" />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup label="Telefone">
                  <FormInput name="phoneNumber" mask="phone" />
                </FormGroup>
              </div>
              <div className="col-md-5">
                <FormGroup label="E-mail">
                  <FormInput name="email" />
                </FormGroup>
              </div>
              <div className="col-12 text-right">
                <FormSubmit>Cadastrar</FormSubmit>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </MainLayout>
  );
};
