import React, { FC, useState, useEffect, useRef } from "react";

import { useAuth } from "../../shared/auth";
import { useLeads } from "../../shared/data";
import { Button } from "../../shared/layout/bootstrap/button/Button";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import { FiFilter } from "react-icons/fi";
import { FilterCheckbox } from "../components/FilterCheckbox";
import { FilterDate } from "../../pages/components/FilterDate";
import FilterSearch from "pages/components/FilterSearch";
import type { IFilterDate } from "pages/components/FilterDate";
import { format } from "date-fns";
import moment from "moment";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";

import { MainLayout } from "pages/components/MainLayout";
import styles from "./LeadView.module.scss";

export const LeadList: FC = () => {
  const { user } = useAuth();
  const leads = useLeads(user.id);

  const action = (
    <Button to="/leads/add" variant="primary">
      Cadastrar Lead
    </Button>
  );

  const [openFilters, setOpenFilters] = useState({
    tradingName: false,
    dthora: false,
    contact: false,
    status: false,
  });

  const toggleFilter = (key: keyof typeof openFilters) => {
    setOpenFilters((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const dthoraRef = useRef<HTMLDivElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const tradingNameRef = useRef<HTMLDivElement>(null);

  const filterRefs = [
    { ref: dthoraRef, key: "dthora" },
    { ref: contactRef, key: "contact" },
    { ref: statusRef, key: "status" },
    { ref: tradingNameRef, key: "tradingName" },
  ];

  const [filteredLeads, setFilteredLeads] = useState([]);
  const [valueFilterTrading, setvalueFilterTrading] = useState("");
  const [dataValueFilter, setDataValueFilter] = useState();
  const [valueFilterContact, setvalueFilterContact] = useState("");
  const [dataValueFilterContact, setDataValueFilterContact] = useState();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [valueFilterStatus, setvalueFilterStatus] = useState([]);

  const filterData = (value) => {
    setDataValueFilter(value);
  };

  const filterTrading = (value) => {
    setvalueFilterTrading(value);
  };

  const filterDataRegister = (value: IFilterDate) => {
    setStartDate(moment(value.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(value.endDate).format("YYYY-MM-DD"));
  };

  const filterDataClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const filterDataContact = (value) => {
    setDataValueFilterContact(value);
  };

  const filterContact = (value) => {
    setvalueFilterContact(value);
  };

  const filterStatus = (value) => {
    setvalueFilterStatus(value);
  };

  useEffect(() => {
    let filtered = leads;

    if (valueFilterTrading) {
      filtered = filtered.filter((item) =>
        item.tradingName
          .normalize("NFKD")
          .replace(/^\s+|\p{M}/gu, "")
          .toLowerCase()
          .includes(
            valueFilterTrading.toLowerCase().replace(/^\s+|\p{M}/gu, "")
          )
      );
    }

    if (startDate && endDate) {
      filtered = filtered.filter((item) => {
        const createdDate = new Date(
          moment(item.created_at).format("YYYY-MM-DD")
        );
        const start = new Date(startDate);
        const end = new Date(endDate);
        return createdDate >= start && createdDate <= end;
      });
    }

    if (valueFilterContact) {
      filtered = filtered.filter((item) =>
        item.leadRepresentative
          .normalize("NFKD")
          .replace(/^\s+|\p{M}/gu, "")
          .toLowerCase()
          .includes(
            valueFilterContact.toLowerCase().replace(/^\s+|\p{M}/gu, "")
          )
      );
    }

    if (valueFilterStatus.length > 0) {
      filtered = filtered.filter((item) =>
        valueFilterStatus.some((status) => item.status.includes(status))
      );
    }
    setFilteredLeads(filtered);
  }, [
    leads,
    valueFilterTrading,
    startDate,
    endDate,
    valueFilterContact,
    valueFilterStatus,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      filterRefs.forEach(({ ref, key }) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpenFilters((prev) => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRefs]);

  const getStatusColor = (status) => {
    switch (status) {
      case "RASCUNHO":
        return "#4498E1";
      case "PENDENTE":
        return "#FF9E15";
      case "APROVADO":
        return "#35DC3E";
      case "REPROVADO":
        return "#C3C3C3";
      default:
        return "";
    }
  };

  return (
    <MainLayout title="Listagem de Leads" action={action}>
      <Table hover>
        <TableHead className={styles.filtro}>
          <TableCell>Detalhes</TableCell>
          <TableCell>CNPJ</TableCell>
          <TableCell>
            Empresa
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("tradingName");
              }}
              style={{ color: valueFilterTrading ? "#000000" : "" }}
            />
            {openFilters.tradingName ? (
              <div ref={tradingNameRef}>
                <FilterSearch
                  id="tradingName"
                  dataValueFilter={dataValueFilter}
                  filterValue={filterTrading}
                  dataFilter={filterData}
                />
              </div>
            ) : null}
          </TableCell>
          <TableCell>Telefone da Empresa</TableCell>
          <TableCell>
            Data/Hora Cadastro
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("dthora");
              }}
              style={{ color: endDate ? "#000000" : "" }}
            />
            {openFilters.dthora ? (
              <div ref={dthoraRef}>
                <FilterDate
                  id="dthora"
                  filterValue={filterDataRegister}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  filterValueClean={filterDataClean}
                />
              </div>
            ) : null}
          </TableCell>
          <TableCell>
            Contato
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("contact");
              }}
              style={{ color: valueFilterContact ? "#000000" : "" }}
            />
            {openFilters.contact ? (
              <div ref={contactRef}>
                <FilterSearch
                  id="contact"
                  dataValueFilter={dataValueFilterContact}
                  filterValue={filterContact}
                  dataFilter={filterDataContact}
                />
              </div>
            ) : null}
          </TableCell>
          <TableCell>E-mail do Contato</TableCell>
          <TableCell>Telefone do Contato</TableCell>
          <TableCell>
            Status
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("status");
              }}
              style={{ color: valueFilterStatus.length > 0 ? "#000000" : "" }}
            />
            {openFilters.status ? (
              <div ref={statusRef}>
                <FilterCheckbox
                  id="status"
                  data={leads}
                  fieldName="status"
                  filterValue={filterStatus}
                  filterValueDefault={valueFilterStatus}
                />
              </div>
            ) : null}
          </TableCell>
        </TableHead>

        <TableBody>
          {leads.length > 0 ? (
            filteredLeads.map((lead) => (
              <TableRow key={lead.id}>
                <TableCell className="text-center">
                  <IconButton to={`/leads/view/${lead.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>
                <TableCell>{lead.cnpj}</TableCell>
                <TableCell>{lead.tradingName}</TableCell>
                <TableCell>{lead.phone}</TableCell>
                <TableCell>
                  {format(new Date(lead.created_at), "dd/MMM/yyyy - HH:mm:ss")}
                </TableCell>
                <TableCell>{lead.leadRepresentative}</TableCell>
                <TableCell>{lead.leadRepMail}</TableCell>
                <TableCell>{lead.leadRepPhone}</TableCell>
                <TableCell>
                  <span style={{ color: getStatusColor(lead.status) }}>
                    {lead.status}
                  </span>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableBody>
              <p>Os Leads cadastrados aparecerão aqui.</p>
            </TableBody>
          )}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
