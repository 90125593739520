import React, { FC, useState, useCallback, useEffect } from "react";
import { data } from "../../shared/data";
import Recaptcha from "react-recaptcha";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { yupResolver } from "@hookform/resolvers/yup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { phoneMask, cnpjMask, zipCodeMask } from "../../pages/components/Masks";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import { MainLayout } from "pages/components/MainLayout";
import ToastAnimated, { showToast } from "pages/components/toast";
import { BiCheckShield } from "react-icons/bi";
import LoadingMask from "pages/components/LoadingMask";
import findZipCode from "../../shared/api/viacep";

import styles from "./RegisterResale.module.scss";

export interface Izipcode {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  localidade: string;
  logradouro: string;
  uf: string;
}

export const RegisterResale: FC = () => {
  const onError = (errors: any, e: any) => console.log(errors, e);

  const [loading, setLoading] = useState(false);

  const [resaleRegister, setResaleRegister] = useState(false);

  const [validCaptcha, setValidCaptcha] = useState(false);

  YupPassword(Yup);
  const validationSchema = Yup.object().shape({
    register_number: Yup.string()
      .required("Campo obrigatório")
      .min(14, "CNPJ inválido."),

    name: Yup.string().required("Campo obrigatório"),
    //trading_name: Yup.string().required("Campo obrigatório"),
    //resale_category: Yup.string()
    //  .required("Selecione o nível de parceria.")
    //  .nullable(),

    name_responsible: Yup.string().required("Campo obrigatório"),
    phone: Yup.string()
      .required("Campo obrigatório")
      .min(10, "Telefone inválido."),

    email: Yup.string()
      .required("Campo obrigatório")
      .email("Digite um e-mail válido"),

    password: Yup.string()
      .required("Campo obrigatório")
      .min(
        8,
        "A senha deve conter 8 ou mais caracteres com pelo menos um de cada: maiúscula, minúscula, número e caractere especial"
      )
      .minLowercase(1, "A senha deve conter pelo menos 1 letra minúscula.")
      .minUppercase(1, "A senha deve conter pelo menos 1 letra maiúscula")
      .minNumbers(1, "A senha deve conter pelo menos 1 número")
      .minSymbols(1, "A senha deve conter pelo menos 1 caractere especial"),

    passwordConfirmation: Yup.string()
      .required("Campo obrigatório")
      .oneOf([Yup.ref("password"), null], "As senhas não conferem"),

    checkTerms: Yup.bool().oneOf([true], "Campo obrigatório"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue, setFocus } =
    useForm(formOptions);
  const { errors } = formState;

  const [zipCode, setZipCode] = useState();

  const checkZipCode = async (e) => {
    if (!e.target.value) return;
    const zipCode = e.target.value.replace(/\D/g, "");
    if (zipCode.length === 8) {
      setZipCode(zipCode);
    }
  };

  useEffect(() => {
    async function getCep() {
      if (zipCode) {
        try {
          const data: Izipcode | any = await findZipCode(zipCode);
          setValue("zipcode", data.cep);
          setValue("address", data.logradouro);
          setValue("district", data.bairro);
          setValue("city", data.localidade);
          setValue("state", data.uf);
          setFocus("number");
        } catch (error) {
          alert("Ocorreu um erro ao buscar o endereço");
        }
      }
    }
    getCep();
  }, [zipCode, setValue, setFocus]);

  const onSubmit = useCallback(
    async (value) => {
      try {
        if (validCaptcha === false) {
          showToast("error", "É necessário preencher o captcha corretamente.");
        } else {
          setLoading(true);
          console.log("valor data", data);
          data
            .post("/resales", value)
            .then(() => {
              setLoading(false);
              setResaleRegister(true);
            })
            .catch(() => {
              setLoading(false);
              alert("Falha ao cadastrar o Canal!");
            });
        }
      } catch (err) {
        setLoading(false);
        console.log("erro:", err);
      }
    },
    [validCaptcha]
  );

  const [state, setState] = useState({
    phone: "",
    register_number: "",
  });

  // specifying your onload callback function
  var callback = function () {};

  var verifyCallback = function (response) {
    console.log("response:", response);
    if (response) {
      setValidCaptcha(true);
    }
  };

  const expiredCallback = () => {
    setValidCaptcha(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <MainLayout title="Cadastro de canais">
      {loading ? <LoadingMask text="Salvando..." /> : null}
      <ToastAnimated />

      {/* <div className={styles.requiredFields}>
        Campos marcados com <span>*</span> são obrigatórios.
      </div> */}
      <Card>
        <CardBody>
          <h6 className="text-primary mt-3 mb-2 pb-3">DADOS DA EMPRESA</h6>
          {resaleRegister === false ? (
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Razão Social*"
                  >
                    <Form.Control type="text" {...register("name")} />

                    {errors.name && (
                      <div className={styles.errorStyling}>
                        <span>{errors.name.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-6 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Nome Fantasia"
                    className="mb-3"
                  >
                    <Form.Control type="text" {...register("trading_name")} />

                    {errors.trading_name && (
                      <div className={styles.errorStyling}>
                        <span>{errors.trading_name.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="CNPJ*"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      {...register("register_number")}
                      name="register_number"
                      value={state.register_number}
                      onChange={(e) => handleChange(cnpjMask(e))}
                    />

                    {errors.register_number && (
                      <div className={styles.errorStyling}>
                        <span>{errors.register_number.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Inscrição Estadual"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      {...register("state_registration")}
                    />
                  </FloatingLabel>
                </div>

                <div className="col-md-4 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Inscrição Municipal"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      {...register("municipal_registration")}
                    />
                  </FloatingLabel>
                </div>

                <div className="col-12 mt-4 pb-3">
                  <h6 className="text-primary mb-3">ENDEREÇO</h6>
                </div>

                <div className="col-md-2 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="CEP"
                    className="mb-3"
                    id="cep"
                  >
                    <Form.Control
                      type="text"
                      {...register("zipcode")}
                      onBlur={checkZipCode}
                      onChange={(e) => handleChange(zipCodeMask(e))}
                    />
                  </FloatingLabel>
                </div>

                <div className="col-md-5 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Rua"
                    className="mb-3"
                    id="rua"
                  >
                    <Form.Control type="text" {...register("address")} />
                  </FloatingLabel>
                </div>

                <div className="col-md-2 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Número"
                    className="mb-3"
                  >
                    <Form.Control type="number" {...register("number")} />
                  </FloatingLabel>
                </div>

                <div className="col-md-3 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Complemento"
                    className="mb-3"
                  >
                    <Form.Control type="text" {...register("complement")} />
                  </FloatingLabel>
                </div>
                <div className="col-md-5 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Bairro"
                    id="bairro"
                    className="mb-3"
                  >
                    <Form.Control type="text" {...register("district")} />
                  </FloatingLabel>
                </div>
                <div className="col-md-5 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Cidade"
                    id="cidade"
                    className="mb-3"
                  >
                    <Form.Control type="text" {...register("city")} />
                  </FloatingLabel>
                </div>

                <div className="col-md-2 ">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="UF"
                    id="uf"
                    className="mb-3"
                  >
                    <Form.Control type="text" {...register("state")} />
                  </FloatingLabel>
                </div>

                <div className="col-12 mt-2">
                  <h6 className="text-primary mt-4 mb-4">
                    DADOS DE CONTATO PRINCIPAL DO CANAL
                  </h6>
                </div>

                <div className="col-md-4 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Nome*"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      {...register("name_responsible", { required: true })}
                    />
                    {errors.name_responsible && (
                      <div className={styles.errorStyling}>
                        <span>{errors.name_responsible.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-3 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Telefone*"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="phone"
                      {...register("phone")}
                      value={state.phone}
                      onChange={(e) => handleChange(phoneMask(e))}
                    />
                    {errors.phone && (
                      <div className={styles.errorStyling}>
                        <span>{errors.phone.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-5 pb-3">
                  <FloatingLabel
                    controlId="formBasicEmail"
                    label="E-mail*"
                    className="mb-3"
                  >
                    <Form.Control type="email" {...register("email")} />
                    {errors.email && (
                      <div className={styles.errorStyling}>
                        <span>{errors.email.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-12 mt-2">
                  <h6 className="text-primary mb-2 mt-4">
                    DADOS DE ACESSO DO CANAL
                  </h6>
                </div>

                <div className="col-6 mt-3 pb-3">
                  <FloatingLabel controlId="floatingTextarea" label="Senha*">
                    <Form.Control type="password" {...register("password")} />
                    {errors.password && (
                      <div className={styles.errorStyling}>
                        <span>{errors.password.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-6 mt-3 pb-3">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Confirmar Senha*"
                    className="mb-3"
                  >
                    <Form.Control
                      type="password"
                      {...register("passwordConfirmation")}
                    />
                    {errors.passwordConfirmation && (
                      <div className={styles.errorStyling}>
                        <span>{errors.passwordConfirmation.message}</span>
                      </div>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-12 text-right mt-3">
                  {errors.checkTerms && (
                    <div className={styles.errorStyling}>
                      <span>{errors.checkTerms.message}</span>
                    </div>
                  )}
                  <Form.Check
                    type="checkbox"
                    label="Autorizo a coleta dos meus dados pessoais para contato e avaliação de parceria.*"
                    {...register("checkTerms")}
                    className={styles.ChecktermsStyling}
                  />

                  <div className={styles.termsStyling}>
                    Ao continuar navegando, o titular concorda de forma
                    inequívoca com o tratamento de seus dados pessoais para uma
                    finalidade determinada. A DNASec ® declara e garante que
                    exerce o tratamento adequado de dados pessoais obtidos
                    através deste canal, conforme nossa{" "}
                    <a href="#privacypolicy">Política de Privacidade</a>, e de
                    acordo com as legislações vigentes, em particular a Lei
                    Geral de Proteção de Dados Pessoais (no 13.709/2018).
                  </div>
                </div>

                <div className="col-12 text-right mt-3">
                  <Recaptcha
                    sitekey="6LeEY20gAAAAAFzqngZq0H851QqIODWUTpg-ykLC"
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={callback}
                    expiredCallback={expiredCallback}
                  />
                </div>
                <div className="col-12 text-right mt-3">
                  <FormSubmit>Cadastrar</FormSubmit>
                </div>
              </div>
            </Form>
          ) : (
            <span className={styles.email}>
              <div>
                <BiCheckShield color="4ECE3D" size={55} />
              </div>
              <div>
                Cadastro realizado com sucesso!
                <br />
                Em breve entraremos em contato, obrigado.
              </div>
            </span>
          )}
        </CardBody>
      </Card>
    </MainLayout>
  );
};
