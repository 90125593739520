import React, { FC, ReactNode } from 'react';

import { PageLayout } from '../../shared/layout/pages/PageLayout';
import { PageHeader } from './PageHeader';
import styles from './MainLayout.module.scss';
interface MainLayoutProps {
  action?: ReactNode;
  arrowBack?: boolean;
  title?: string;
  button?: boolean;
}

export const MainLayout: FC<MainLayoutProps> = (props) => (
  <PageLayout className={styles.root} header={PageHeader} {...props} />
);
