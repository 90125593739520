import React, { FC } from "react";

import { FormContextProvider, FormDataValue } from "./FormContext";

export type FormCallback = (_: FormDataValue) => any;

interface FormProps {
  className?: string;
  onChange?: (_: FormDataValue) => void;
  onSubmit?: FormCallback;
  readOnly?: boolean;
  target?: string;
  value?: FormDataValue;
}

export const Form: FC<FormProps> = ({ className, children, ...props }) => {
  return (
    <FormContextProvider {...props}>
      <form noValidate className={className}>
        {children}
      </form>
    </FormContextProvider>
  );
};
