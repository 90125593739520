import React, { FC, useState } from "react";
import Form from "react-bootstrap/Form";

import styles from "./Styles.module.scss";

export interface IFilter {
  id: string;
  data: Record<string, any>[];
  fieldName: string;
  // onSubmit: void;
  filterValue: any;
  filterValueDefault: any;
}

export const FilterCheckbox: FC<IFilter> = ({
  data,
  fieldName,
  filterValue,
  filterValueDefault,
}) => {
  const [checked, setChecked] = useState(filterValueDefault);

  const uniqueItems = Array.from(new Set(data.map((item) => item[fieldName])));

  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    filterValue(updatedList);
  };

  return (
    <div className={styles.FilterList}>
      <Form>
        {uniqueItems.map((uniqueFieldName) => (
          <div key={uniqueFieldName} className={styles.FilterListItem}>
            <input
              className={styles.FilterCheckBox}
              value={uniqueFieldName}
              type="checkbox"
              onChange={handleCheck}
              checked={filterValueDefault.includes(uniqueFieldName)}
            />
            <div className={styles.FilterLabelAll}>{uniqueFieldName}</div>
          </div>
        ))}
      </Form>
    </div>
  );
};
