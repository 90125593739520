export enum CommissionStatus {
  Released = 1,
  Paid = 2,
  Canceled = 3,
  InvoiceReceived = 4,
  Pending = 5,
  Analysis = 6
}

export const ECommissionStatus = new Map<CommissionStatus, string>([
  [CommissionStatus.Released, 'Liberado'],
  [CommissionStatus.Paid, 'Pago'],
  [CommissionStatus.Canceled, 'Cancelado'],
  [CommissionStatus.InvoiceReceived, 'Boleto Recebido'],
  [CommissionStatus.Pending, 'Pendente'],
  [CommissionStatus.Analysis, 'Em análise']
])