import React, { FC } from 'react'

export interface TableCellProps {
  align?: 'center' | 'left' | 'right'
  className?: string
  header?: boolean
}

export const TableCell: FC<TableCellProps> = ({align, header, ...props}) => {
  const Component = header ? 'th' : 'td'
  return (
    <Component {...props} style={{textAlign: align}} />
  )
}
