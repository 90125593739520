import React from "react";

export const phoneMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  let lenght = e.target.value.length;

  //if (lenght === 10) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  //}
  if (lenght === 15) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    e.target.maxLength = 15;
  }

  e.target.value = value;
  return e;
};

export const cnpjMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  e.target.maxLength = 18;

  value = value.replace(/\D/g, "");
  value = value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );

  e.target.value = value;
  return e;
};

export const leadRepPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  let lenght = e.target.value.length;

  //if (lenght === 10) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  //}
  if (lenght === 15) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    e.target.maxLength = 15;
  }

  e.target.value = value;
  return e;
};

export const zipCodeMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  e.target.maxLength = 8;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

  e.target.value = value;
  return e;
};
