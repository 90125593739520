import React, { FC } from "react";
import { Link } from "react-router-dom";

import { classNames } from "../../util/class-names";

import styles from "./Header.module.scss";

interface HeaderProps {
  className?: string;
  transparent?: boolean;
}

export const Header: FC<HeaderProps> = ({
  className,
  children,
  transparent,
}) => (
  <nav className={classNames("navbar", className)}>
    <div className="container">
      <Link className="navbar-brand" to="/">
        <img
          className={styles.logo}
          src="/img/dnaseccinza.png"
          alt="DNASec Logo"
        />
      </Link>
      {children}
    </div>
  </nav>
);
