import { data } from './data-service'
import { Account, AccountResale } from './interfaces'


const AUTH_TOKEN_KEY = 'auth_token'
const AUTH_REFRESH_TOKEN_KEY = 'refresh_token'
const AUTH_USER_KEY = 'auth_user'

export function getToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function setToken(token: string | null) {

  if (token === null) {
    localStorage.removeItem(AUTH_TOKEN_KEY)
  } else {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  }
}

export function getRefreshToken() {
  return localStorage.getItem(AUTH_REFRESH_TOKEN_KEY)
}

export function setRefreshToken(token: string | null) {
  if (token === null) {
    localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY)
  } else {
    localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, token)
  }
}

export function getUser(): Account | null {
  try {
    return JSON.parse(localStorage.getItem(AUTH_USER_KEY)!)
  } catch (e) {
    return null
  }
}

export function setUser(user: any) {
  localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user))
}

export function getResale() {
  return data.get<AccountResale>('/api/accounts/getresale')
    .then(response => response.body)
    .catch(() => null)
}
