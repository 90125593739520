import React, { FC } from "react";

import { useCommissions } from "../../shared/data";
import { ECommissionStatus } from "../../shared/data/enums";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";
import { formatCurrency, formatCnpj } from "../../shared/util/format";

import { MainLayout } from "pages/components/MainLayout";

function latestQuote(order: undefined | Order, prop: string) {
  const [latest] = order?.opportunity?.quotes?.slice(-1);
  return latest?.[prop];
}

export const CommissionList: FC = () => {
  const commissions = useCommissions();
  return (
    <MainLayout title="Listagem de Comissões">
      <Table hover>
        <TableHead>
          <TableRow>
            <TableCell>Código da Assinatura</TableCell>
            <TableCell>Código da Comissão</TableCell>
            <TableCell>Razão Social</TableCell>
            <TableCell>CNPJ</TableCell>
            <TableCell>Valor do Título</TableCell>
            <TableCell>Status NF-e</TableCell>
            <TableCell>Status Pagamento</TableCell>
            <TableCell>Comissão Líquida</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {commissions &&
            commissions.map(
              ({ id, code, order, invoiceStatus, paymentStatus, value }) => (
                <TableRow key={id}>
                  <TableCell>{order.code}</TableCell>
                  <TableCell>{code}</TableCell>
                  <TableCell>
                    {"order.opportunity.lead.client.companyName"}
                  </TableCell>
                  <TableCell>
                    {formatCnpj("order.opportunity.lead.client.cnpj")}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(latestQuote(order, "finalValue"))}
                  </TableCell>
                  <TableCell>{ECommissionStatus.get(invoiceStatus)}</TableCell>
                  <TableCell>{ECommissionStatus.get(paymentStatus)}</TableCell>
                  <TableCell>{formatCurrency(value)}</TableCell>
                  <TableCell>
                    <IconButton to={`/commissions/view/${id}`}>
                      <IconEyeFill />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
