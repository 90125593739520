export enum LeadStatus {
  Registered = 1,
  Approved = 2,
  Disapproved = 3,
  ClosedGain = 4,
  ClosedLost = 5,
  Closed = 6,
  ProcessingOrder = 7
}

export const ELeadStatus = new Map<LeadStatus, string>([
  [LeadStatus.Registered, 'Registrado'],
  [LeadStatus.Approved, 'Aprovado'],
  [LeadStatus.Disapproved, 'Rejeitado'],
  [LeadStatus.ClosedGain, 'Fechado Ganho'],
  [LeadStatus.ClosedLost, 'Fechado Perdido'],
  [LeadStatus.Closed, 'Encerrado'],
  [LeadStatus.ProcessingOrder, 'Pedido em Processamento']
])
