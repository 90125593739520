import React, { FC, useCallback, useState, useEffect } from "react";
import styles from "./ContactForm.module.scss";
import { data } from "../../shared/data";
interface IContactData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const ContactForm: FC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {}, [isEmailSent]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const formValue: IContactData = {
        name: e.target[0].value,
        email: e.target[1].value,
        subject: e.target[2].value,
        message: e.target[3].value,
      };

      data.post("/contact", formValue);
      setIsEmailSent(!isEmailSent);
    },
    [isEmailSent]
  );

  return (
    <>
      {isEmailSent ? (
        <div className={styles.formDiv}>
          <h1>Mensagem Enviada!</h1>
          <p>Nossos representantes entrarão em contato.</p>
        </div>
      ) : (
        <div className={styles.formDiv}>
          <div className="formHeader">
            <h2>Entre em contato</h2>
            <p>
              Será um prazer lhe atender!
              <br /> Nossa equipe responderá o mais breve possível.
            </p>
          </div>
          <div className={styles.form}>
            <div className="ContactForm">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="contactForm">
                      <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="row formRow pb-2">
                          <div className="col">
                            <input
                              type="text"
                              name="name"
                              className="form-control formInput"
                              placeholder="Nome"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="row formRow pb-2">
                          <div className="col">
                            <input
                              type="email"
                              name="email"
                              className="form-control formInput"
                              placeholder="E-mail"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="row formRow pb-2">
                          <div className="col">
                            <input
                              type="text"
                              name="subject"
                              className="form-control formInput"
                              placeholder="Assunto"
                            ></input>
                          </div>
                        </div>
                        <div className="row formRow pb-4">
                          <div className="col">
                            <textarea
                              rows={3}
                              name="message"
                              className="form-control formInput"
                              placeholder="Mensagem"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <button className="btn btn-secondary" type="submit">
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
