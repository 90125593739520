import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { usePermission } from "../../shared/auth";
import { data, useContact } from "../../shared/data";
import { EContactStatus, ContactStatus } from "../../shared/data/enums";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";

import { MainLayout } from "pages/components/MainLayout";

export const ContactView: FC<ViewComponentProps> = ({ match: { params } }) => {
  const contact = useContact(params.id);
  const editContactPermission =
    usePermission("contacts", "edit") &&
    contact &&
    contact.status !== ContactStatus.Approved &&
    contact.status !== ContactStatus.Disapproved;
  const history = useHistory();
  const handleApprove: FormCallback = () => {
    return data
      .post("/api/accounts/contact", { contactId: contact.id })
      .then(() => history.push("/contacts"));
  };
  const handleDisapprove: FormCallback = () => {
    const status = ContactStatus.Disapproved;
    return data
      .post("/api/contacts/status", { id: contact.id, status })
      .then(() => history.push("/contacts"));
  };
  return (
    <MainLayout title="Visualizar contato" arrowBack>
      {contact && (
        <Card>
          <CardBody>
            <Form readOnly value={contact}>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup label="Nome">
                    <FormInput name="name" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Telefone">
                    <FormInput name="phoneNumber" mask="phone" />
                  </FormGroup>
                </div>
                <div className="col-md-8">
                  <FormGroup label="E-mail">
                    <FormInput name="email" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Status">
                    <FormInput value={EContactStatus.get(contact.status)} />
                  </FormGroup>
                </div>
              </div>
            </Form>
            {editContactPermission && (
              <div className="d-flex justify-content-between">
                <Form onSubmit={handleDisapprove}>
                  <FormSubmit variant="primary" outline>
                    REPROVAR
                  </FormSubmit>
                </Form>
                <Form onSubmit={handleApprove}>
                  <FormSubmit variant="primary">APROVAR</FormSubmit>
                </Form>
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
