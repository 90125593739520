import React, { FC } from "react";
import { format } from "date-fns";
import { useLeadsAdministrator, useResales } from "../../../shared/data";
import { IconButton } from "../../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../../shared/layout/bootstrap/icon";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../shared/layout/bootstrap/table";
import { MainLayout } from "pages/components/MainLayout";

interface IlistLead {
  id: string;
  resale_name: string;
  lead_created_at: Date;
  lead_tradingName: string;
  lead_leadRepresentative: string;
  lead_leadRepMail: string;
  lead_status: string;
}

export const LeadListAdministrator: FC = () => {
  const resales = useResales();
  const leads = useLeadsAdministrator();

  let listLead: IlistLead[] = [];

  if (leads && resales) {
    leads.map((lead) =>
      resales.map((resale) => {
        if (resale.id === lead.resale_id) {
          let list = {
            id: lead.id,
            lead_created_at: lead.created_at,
            resale_name: resale.name,
            lead_tradingName: lead.tradingName,
            // lead_phone: lead.phone,
            lead_leadRepresentative: lead.leadRepresentative,
            lead_leadRepMail: lead.leadRepMail,
            // lead_leadRepPhone: lead.leadRepPhone,
            lead_status: lead.status,
          };
          listLead.push(list);
        }
      })
    );
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "PENDENTE":
        return "#FF9E15";
      case "APROVADO":
        return "#35DC3E";
      case "REPROVADO":
        return "#C3C3C3";
      default:
        return "";
    }
  };

  return (
    <MainLayout title="Listagem de Leads">
      {leads ? (
        <Table hover>
          <TableHead>
            <TableCell>Detalhes</TableCell>
            <TableCell>Canal</TableCell>
            <TableCell>Data/Hora Cadastro</TableCell>
            <TableCell>Empresa Lead</TableCell>
            <TableCell>Contato</TableCell>
            <TableCell>E-mail do Contato</TableCell>
            <TableCell>Status</TableCell>
          </TableHead>

          <TableBody>
            {listLead?.map((lead, index) => (
              <TableRow key={index}>
                <TableCell className="text-center">
                  <IconButton to={`/approve/leads/view/${lead.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>
                <TableCell>{lead.resale_name}</TableCell>
                <TableCell>
                  {format(
                    new Date(lead.lead_created_at),
                    "dd/MMM/yyyy - HH:mm:ss"
                  )}
                </TableCell>
                <TableCell>{lead.lead_tradingName}</TableCell>
                <TableCell>{lead.lead_leadRepresentative}</TableCell>
                <TableCell>{lead.lead_leadRepMail}</TableCell>
                <TableCell>
                  <span style={{ color: getStatusColor(lead.lead_status) }}>
                    {lead.lead_status}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <TableBody>
          <TableCell>
            <h6>Carregando...</h6>
          </TableCell>
        </TableBody>
      )}
    </MainLayout>
  );
};
