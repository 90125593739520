import React, { Children, FC, ReactElement } from 'react'

import { TableRowProps } from './TableRow'

import styles from './TableBody.module.scss'

interface TableBodyProps { }

export const TableBody: FC<TableBodyProps> = ({children}) => (
  <tbody className={styles.body}>
    {Children.map(children as ReactElement<TableRowProps>, child => {
      return React.cloneElement(child, {header: false})
    })}
  </tbody>
)