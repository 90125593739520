import React, { Children, FC } from "react";

import styles from "./FormInput.module.scss";

interface FormGroupProps {
  label?: string;
  inputEyeIcon?: boolean;
}

// TODO: migrar counter para hooks, para não executar a cada re-render
let counter = 0;

export const FormGroup: FC<FormGroupProps> = ({
  children,
  label,
  inputEyeIcon,
}) => {
  const id = `form-input-${++counter}`;
  const [child] = Children.toArray(children) as React.ReactElement[];
  return (
    <div className={`form-group ${inputEyeIcon ? styles.formGroup : ""}`}>
      <label htmlFor={id}>{label}</label>
      {child && React.cloneElement(child, { label })}
    </div>
  );
};
