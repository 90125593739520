import React, { ChangeEvent, FC, useRef } from "react";

import { classNames } from "../util/class-names";

import { FormValue } from "./FormContext";
import { useFormValueContext } from "./FormValueContext";

import styles from "./FormCheck.module.scss";

interface FormCheckProps {
  checked?: boolean;
  className?: string;
  inline?: boolean;
  name?: string;
  onChange?: (_: boolean) => void;
  value?: FormValue;
}

let uniqueId = 0;

export const FormCheck: FC<FormCheckProps> = ({
  checked,
  children,
  name,
  onChange,
  value,
}) => {
  const [current, setCurrent] = useFormValueContext() || [];
  const id = useRef(`form-check-${++uniqueId}`);
  const item = useRef(name ? { [name]: value } : value);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
    if (setCurrent) {
      if (event.target.checked) {
        setCurrent((current: any[]) => [...current, item.current]);
      } else {
        setCurrent((current: FormValue[]) => {
          return [...current].filter((element) => item.current !== element);
        });
      }
    }
  };

  const input = {
    checked: checked,
    // (Array.isArray(current)
    //   ? current.includes(item.current as any)
    //   : checked) ?? false,
    // id: id.current,
    onChange: handleChange,
  };

  return (
    <div
      className={classNames(styles.check, "custom-control", "custom-checkbox")}
    >
      <input type="checkbox" className="custom-control-input" {...input} />{" "}
      <label className="custom-control-label" htmlFor={id.current}>
        <div className={styles.childrenLabel}>{children}</div>
      </label>
    </div>
  );
};
