import React, { FC, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { data } from "../../shared/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useHistory } from "react-router-dom";
import ToastAnimated, { showToast } from "pages/components/toast";
import FormLabel from "react-bootstrap/FormLabel";
import { useForm } from "react-hook-form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";
import { PageLayout } from "../../shared/layout/pages/PageLayout";

import styles from "./ResetPassword.module.scss";
import LoadingMask from "pages/components/LoadingMask";

interface IResetPassword {
  password: string;
  passwordConfirmation: string;
}

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const ResetPassword: FC = () => {
  const onError = (errors: any, e: any) => console.log(errors, e);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const token = query.get("token");

  YupPassword(Yup);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Campo obrigatório")
      .min(
        8,
        "A senha deve conter 8 ou mais caracteres com pelo menos um de cada: maiúscula, minúscula, número e caractere especial"
      )
      .minLowercase(1, "A senha deve conter pelo menos 1 letra minúscula.")
      .minUppercase(1, "A senha deve conter pelo menos 1 letra maiúscula")
      .minNumbers(1, "A senha deve conter pelo menos 1 número")
      .minSymbols(1, "A senha deve conter pelo menos 1 caractere especial"),

    passwordConfirmation: Yup.string()
      .required("Campo obrigatório")
      .oneOf([Yup.ref("password"), null], "As senhas não conferem"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = useCallback(
    async (value: IResetPassword) => {
      setLoading(true);
      await data
        .post(`/password/reset`, {
          token,
          value,
        })
        .then(async () => {
          setLoading(false);
          showToast("success", "Sua senha foi alterada com sucesso.");
          await sleep(2000);
          history.push("/login");
        })
        .catch(() => {
          setLoading(false);
          showToast(
            "error",
            "Ocorreu um erro ao tentar trocar sua senha, tente novamente."
          );
        });
    },
    [history, token]
  );

  return (
    <div className={styles.root}>
      {loading ? <LoadingMask text="Processando..." /> : null}
      <ToastAnimated />
      <PageLayout className={styles.page}>
        <Card className={styles.card}>
          <CardBody>
            <h4 className="text-center">Alterar senha</h4>

            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <FormGroup label="Senha">
                <FormLabel className={styles.label}>
                  <Form.Control type="password" {...register("password")} />
                  {errors.password && (
                    <div className={styles.errorStyling}>
                      <span>{errors.password.message}</span>
                    </div>
                  )}
                </FormLabel>
              </FormGroup>

              <FormGroup label="Confirmar Senha">
                <FormLabel className={styles.label}>
                  <Form.Control
                    type="password"
                    autoComplete="current-password"
                    {...register("passwordConfirmation")}
                  />

                  {errors.passwordConfirmation && (
                    <div className={styles.errorStyling}>
                      <span>{errors.passwordConfirmation.message}</span>
                    </div>
                  )}
                </FormLabel>
              </FormGroup>

              <FormSubmit className="mt-4 mb-3 text-center" block>
                Enviar
              </FormSubmit>
            </Form>
          </CardBody>
        </Card>
      </PageLayout>
      <div className={styles.quote}>
        <div className={styles.background}>
          <img alt="" src="/img/auth.jpg" />
        </div>
      </div>
      <ToastAnimated />
    </div>
  );
};
