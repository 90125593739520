export enum ContactStatus {
  Registered = 1,
  Approved = 2,
  Disapproved = 3
}

export const EContactStatus = new Map<ContactStatus, string>([
  [ContactStatus.Registered, 'Registrado'],
  [ContactStatus.Approved, 'Aprovado'],
  [ContactStatus.Disapproved, 'Rejeitado']
])