import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Account,
  AccountResale,
  getToken,
  getRefreshToken,
  getUser,
} from "../data";

import { authenticateUser, authenticateToken } from "./auth-service";

interface AuthContextInterface {
  login: (url: string, value: any) => Promise<void>;
  logout: () => void;
  loggedIn: boolean | null;
  user: Account | null;
  resale: AccountResale | null | undefined;
  IsActivated: boolean | null;
}

const AuthContext = createContext<AuthContextInterface>(null);

export const AuthContextProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextInterface>({
    login: (url, value): any => {
      return authenticateUser(url, value)
        .then(() => {
          const loggedIn = true;
          const { resale, ...user } = getUser();
          setAuth((auth: AuthContextInterface) => {
            return { ...auth, loggedIn, user, resale, IsActivated: true };
          });
        })
        .catch((error) => {
          if (error.body.message === "Resale not activated.") {
            setAuth((auth: AuthContextInterface) => {
              localStorage.setItem(
                "@PortalMessage",
                "O seu cadastro ainda não está ativo"
              );
              return { ...auth, IsActivated: true };
            });
          }
        });
    },
    logout: () => {
      localStorage.clear();
      setAuth((auth) => ({
        ...auth,
        loggedIn: false,
        user: null,
        IsActivated: null,
      }));
    },
    loggedIn: null,
    user: null,
    resale: null,
    IsActivated: null,
  });

  useEffect(() => {
    // Determina se o usuário já está logado ou não ao entrar no app
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (token && refreshToken) {
      authenticateToken().then(() => {
        const loggedIn = true;
        const { resale, ...user } = getUser();
        setAuth((auth) => ({ ...auth, loggedIn, user, resale }));
      });
    } else {
      setAuth((auth) => ({ ...auth, loggedIn: false }));
    }
  }, []);
  if (auth.loggedIn !== null) {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  } else {
    // Está carregando se o usuário está logado ou não
    return null;
  }
};

export function useAuth(): AuthContextInterface {
  return useContext(AuthContext)!;
}
