import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthContextProvider, Authenticated, Guest } from "./shared/auth";

import { Login } from "./pages/signIn/Login";
import { FistAcess } from "./pages/FirstAccess/FistAcess";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";

import { RegisterResale } from "./pages/Resale/RegisterResale";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { ClientList } from "./pages/clients/ClientList";
import { ClientView } from "./pages/clients/ClientView";
import { Profile } from "./pages/components/Profile";
import { CommissionList } from "./pages/commissions/CommissionList";
import { CommissionView } from "./pages/commissions/CommissionView";
import { ContactAdd } from "./pages/contacts/ContactAdd";
import { ContactList } from "./pages/contacts/ContactList";
import { ContactView } from "./pages/contacts/ContactView";
import { LeadListAdministrator } from "./pages/administrator/leads/LeadListAdministrator";
import { LeadViewAdministrator } from "./pages/administrator/leads/LeadViewAdministrator";

import { LeadApprove } from "./pages/leads/LeadApprove";
import { LeadAdd } from "./pages/leads/LeadAdd";
import { LeadList } from "./pages/leads/LeadList";
import { LeadView } from "./pages/leads/LeadView";

import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { OpportunityList } from "./pages/opportunities/OpportunityList";
import { OpportunityQuote } from "./pages/opportunities/OpportunityQuote";
import { OpportunityOrder } from "./pages/opportunities/OpportunityOrder";
import { OpportunityView } from "./pages/opportunities/OpportunityView";
import { OrderList } from "./pages/orders/OrderList";
import { OrderView } from "./pages/orders/OrderView";
import { ResaleAdd } from "./pages/resales/ResaleAdd";
import { ResaleList } from "./pages/administrator/resale/ResaleList";
import { ResaleView } from "./pages/administrator/resale/ResaleView";
import { Logout } from "./auth/Logout";
import { RegisterContact } from "./auth/RegisterContact";
import { SalesHome } from "./auth/SalesHome";
import { ContactPage } from "./auth/ContactPage";

import "./styles.scss";

export const App: FC = () => (
  <AuthContextProvider>
    <Guest component={Switch}>
      <Route path="/login" component={Login} />
      <Route path="/register/contact" component={RegisterContact} />
      <Route path="/register/resale" component={RegisterResale} />
      <Route path="/sales" component={SalesHome} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/privacypolicy" component={PrivacyPolicyPage} />
      <Route path="/fistAcess" component={FistAcess} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Route path="/reset_password" component={ResetPassword} />
      <Redirect to="/sales" />
    </Guest>
    <Authenticated component={Switch}>
      <Route path="/Dashboard" exact component={Dashboard} />
      <Route path="/profile" component={Profile} />
      <Route path="/clients" exact component={ClientList} />
      <Route path="/clients/view/:id" component={ClientView} />
      <Route path="/commissions" exact component={CommissionList} />
      <Route path="/commissions/view/:id" component={CommissionView} />
      <Route path="/contacts" exact component={ContactList} />
      <Route path="/contacts/add" component={ContactAdd} />
      <Route path="/contacts/view/:id" component={ContactView} />
      <Route path="/clients" exact component={ClientList} />
      <Route path="/leads" exact component={LeadList} />
      <Route path="/leads/add" component={LeadAdd} />
      <Route path="/leads/approve/:id" component={LeadApprove} />
      <Route path="/leads/view/:id" component={LeadView} />
      <Route path="/approve/leads/view/:id" component={LeadViewAdministrator} />

      <Route path="/approve/leads" component={LeadListAdministrator} />

      <Route path="/opportunities" exact component={OpportunityList} />
      <Route path="/opportunities/quote/:id" component={OpportunityQuote} />
      <Route path="/opportunities/order/:id" component={OpportunityOrder} />
      <Route path="/opportunities/view/:id" component={OpportunityView} />

      <Route path="/orders" exact component={OrderList} />
      <Route path="/orders/view/:id" component={OrderView} />
      <Route path="/resales" exact component={ResaleList} />
      <Route path="/resales/add" component={ResaleAdd} />
      <Route path="/resales/view/:id" component={ResaleView} />
      <Route path="/logout" component={Logout} />
      <Redirect to="/Dashboard" />
    </Authenticated>
  </AuthContextProvider>
);
