import React, { FC } from 'react'

import { FormDataValue, useFormContext } from './FormContext'
import { FormValueContextProvider } from './FormValueContext'

interface FormRadioGroupProps {
  name?: string
  value?: FormDataValue
}

export const FormRadioGroup: FC<FormRadioGroupProps> = ({children, name, value}) => {
  const form = useFormContext()
  const current = value || form.get?.(name)
  return (
    <FormValueContextProvider onChange={value => form.set?.(name, value)} value={current}>
      {children}
    </FormValueContextProvider>
  )
}