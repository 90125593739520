export enum RevenueCategories {
  Until500k = 1,
  From500kTo1kk = 2,
  From1kkTo3kk = 3,
  From3kkTo5kk = 4,
  Above5kk = 5
}

export const ERevenueCategories = new Map<RevenueCategories, string>([
  [RevenueCategories.Until500k, 'Até 500 mil'],
  [RevenueCategories.From500kTo1kk, '500 mil - 1M'],
  [RevenueCategories.From1kkTo3kk, '1M - 3M'],
  [RevenueCategories.From3kkTo5kk, '3M - 5M'],
  [RevenueCategories.Above5kk, 'Acima de 5M']
])