import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useCommissions() {
  const [commissions, setCommissions] = useState<Commission[]>()
  useEffect(() => {
    data.get<Commission[]>('/api/commissions')
      .then(response => response.body)
      .then(body => setCommissions(body))
  }, [])
  return commissions
}

export function useCommission(id: string) {
  const [commission, setCommission] = useState<Commission>()
  useEffect(() => {
    data.get<Commission>(`/api/commissions/${id}`)
      .then(response => response.body)
      .then(commission => setCommission(commission))
  }, [id])
  return commission
}