export enum OrderStatus {
  Active = 1,
  Suspended = 2,
  Canceled = 3
}

export const EOrderStatus = new Map<OrderStatus, string>([
  [OrderStatus.Active, 'Ativo'],
  [OrderStatus.Suspended, 'Suspenso'],
  [OrderStatus.Canceled, 'Cancelado']
])