import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useLeads(user_id: string) {
  const [ leads, setLeads ] = useState<Lead[]>([])
  useEffect(() => {
    data.get<Lead[]>(`/leads/${user_id}`)
      .then(response => response.body)
      .then(leads => setLeads(leads!))
  }, [user_id])
  return leads
}

export function useLead(id: string) {
  const [ lead, setLead ] = useState<Lead>()
  useEffect(() => {
    data.get<Lead>(`/viewlead/${id}`)
      .then(response => response.body)
      .then(lead => setLead(lead!))
  }, [id])
  return lead
}

export function useLeadsAdministrator() {
  const [ leads, setLeads ] = useState<Lead[]>([])
  useEffect(() => {
    data.get<Lead[]>(`/leadAdministrator/`)
      .then(response => response.body)
      .then(leads => setLeads(leads!))
  }, [])
  return leads
}