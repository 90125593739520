import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import styles from "./HeaderHome.module.scss";

const HeaderHome: React.FC = () => (
  <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <div className="row">
          <Link className="navbar-brand" to="/">
            <img
              className={styles.logo}
              src="/img/dnaseccinza.png"
              alt="DNASec Logo"
            />
          </Link>
        </div>
        <div className="d-flex flex-row gap-1">
          <Button href="#/register/resale" variant="warning" className="py-3">
            Seja um Canal
          </Button>{" "}
          <Button href="#/login" variant="secondary" className="py-3">
            Portal do Canal
          </Button>{" "}
        </div>
      </div>
    </nav>
  </>
);

export default HeaderHome;
