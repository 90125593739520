import React, { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import { classNames } from '../../../util/class-names'

import styles from './IconButton.module.scss'

interface IconButtonProps {
  className?: string
  onClick?: () => void
  to?: string
}

export const IconButton: FC<IconButtonProps> = ({className, onClick, to, ...props}) => {
  const classes = classNames(className, styles.button)
  if (to) {
    return (
      <Link className={classes} to={to} {...props} />
    )
  } else {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }
    }
    return (
      <button className={classes} onClick={handleClick} {...props} />
    )
  }
}
