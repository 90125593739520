import React, { ComponentType, FC } from 'react'

import { useAuth } from './AuthContext'

interface GuestProps {
  component: ComponentType
}

export const Guest: FC<GuestProps> = ({children, component: Component}) => {
  const auth = useAuth()
  if (auth.loggedIn === false) {
    return (
      <Component>
        {children}
      </Component>
    )
  } else {
    return null
  }
}
