import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { usePermission } from "../../shared/auth";
import { data, useOpportunity } from "../../shared/data";
import { EPaymentTypes } from "../../shared/data/enums";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSelect } from "../../shared/form/FormSelect";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";

import { MainLayout } from "pages/components/MainLayout";

export const OpportunityOrder: FC<ViewComponentProps> = ({
  match: { params },
}) => {
  const history = useHistory();
  const opportunity = useOpportunity(params);
  const editOpportunityPermission = usePermission("opportunities", "edit");
  const handleSubmit: FormCallback = (value) => {
    return data.post("/api/orders", value).then(() => history.push("/orders"));
  };
  return (
    <MainLayout title="Fechar Assinatura com Oportunidade" arrowBack>
      {opportunity && (
        <Card>
          <CardBody>
            <Form readOnly value={opportunity.lead}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="client.companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="client.tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="CNPJ">
                    <FormInput name="client.cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Quantidade de Titulares">
                    <FormInput name="client.numberHolders" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Ramo de Negócios">
                    <FormInput name="client.lineOfBusiness" />
                  </FormGroup>
                </div>
                <div className="col-md-7">
                  <FormGroup label="Link do site">
                    <FormInput name="client.webSiteUrl" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Telefone">
                    <FormInput name="client.phone" />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <h6 className="text-primary">ENDEREÇO</h6>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Rua">
                    <FormInput name="client.street" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Número">
                    <FormInput name="client.number" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Complemento">
                    <FormInput name="client.complement" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Bairro">
                    <FormInput name="client.neighborhood" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Cidade">
                    <FormInput name="client.city" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="UF">
                    <FormInput name="client.state" />
                  </FormGroup>
                </div>
              </div>
            </Form>
            {editOpportunityPermission && (
              <Form
                onSubmit={handleSubmit}
                value={{
                  opportunityId: opportunity.id,
                  orderDate: new Date().toISOString(),
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup label="E-mail para Fatura">
                      <FormInput name="invoiceEmail" />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup label="Pagamento">
                      <FormSelect
                        name="paymentType"
                        options={Array.from(
                          EPaymentTypes.entries(),
                          ([value, label]) => ({ label, value })
                        )}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup label="Dia Vencimento">
                      <FormInput name="monthlyPaymentExpirationDay" />
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <h6>Contato Financeiro</h6>
                  </div>
                  <div className="col-md-7">
                    <FormGroup label="E-mail">
                      <FormInput name="financialContactEmail" />
                    </FormGroup>
                  </div>
                  <div className="col-md-5">
                    <FormGroup label="Telefone">
                      <FormInput name="financialContactPhone" mask="phone" />
                    </FormGroup>
                  </div>
                  <div className="col-md-12">Informações do DPO</div>
                  <div className="col-md-5">
                    <FormGroup label="Nome">
                      <FormInput name="dpoName" />
                    </FormGroup>
                  </div>
                  <div className="col-md-7">
                    <FormGroup label="E-mail">
                      <FormInput name="dpoEmail" />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Telefone Fixo">
                      <FormInput name="dpoLandLinePhone" mask="phone" />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Telefone Móvel">
                      <FormInput name="dpoMobilePhone" mask="phone" />
                    </FormGroup>
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <FormSubmit>Confirmar</FormSubmit>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
