import React, { Children, FC, ReactElement } from 'react'

import { classNames } from '../../../util/class-names'

import { TableRowProps } from './TableRow'

import classes from './TableHead.module.scss'

interface TableHeadProps {
  className?: string
}

export const TableHead: FC<TableHeadProps> = ({className, children}) => (
  <thead className={classNames(classes.head, className)}>
    <tr>
      {Children.map(children as ReactElement<TableRowProps>, child => {
        return React.cloneElement(child, {header: true})
      })}
    </tr>
  </thead>
)