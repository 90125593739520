import React, { FC, useState } from "react";
import { data, useResale } from "../shared/data";
import { Form, FormCallback } from "../shared/form/Form";
import { FormGroup } from "../shared/form/FormGroup";
import { FormInput } from "../shared/form/FormInput";
import { FormSubmit } from "../shared/form/FormSubmit";
import { Card, CardBody } from "../shared/layout/bootstrap/card";
import { Row, Col } from "../shared/layout/bootstrap/grid";
import { MainLayout } from "pages/components/MainLayout";

export const RegisterContact: FC = () => {
  const [resaleKey, setResaleKey] = useState<ResaleKey>();
  const resale = useResale(resaleKey);
  const handleResale: FormCallback = ({
    register_number,
  }: {
    register_number: string;
  }) => {
    if (register_number && register_number.length === 14) {
      setResaleKey({ register_number });
    }
  };
  const handleSubmit: FormCallback = (value) => {
    return data
      .post("/api/contacts", value)
      .then(() => window.location.assign("/"));
  };
  return (
    <MainLayout title="Cadastro de Contato">
      <Card>
        <CardBody>
          <Form onChange={handleResale}>
            <Row>
              <Col md={3}>
                <FormGroup label="Revenda">
                  <FormInput name="cnpj" mask="cnpj" />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup label="Nome Fantasia">
                  <FormInput readOnly value={resale?.trading_name} />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup label="Razão Social">
                  <FormInput readOnly value={resale?.name} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Form
            onSubmit={handleSubmit}
            target="/"
            value={{ resaleId: resale?.id }}
          >
            <Row>
              <Col md={4}>
                <FormGroup label="Nome">
                  <FormInput name="name" />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup label="Telefone">
                  <FormInput name="phoneNumber" mask="phone" />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup label="E-mail">
                  <FormInput name="email" />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <FormSubmit>Cadastrar</FormSubmit>
            </div>
          </Form>
        </CardBody>
      </Card>
    </MainLayout>
  );
};
