import React, { FunctionComponent, useState } from "react";
import Form from "react-bootstrap/Form";
import { MdClear } from "react-icons/md";

import styles from "./Styles.module.scss";

interface ISearch {
  id: string;
  dataValueFilter?: IDataFilter;
  filterValue: (a: string) => void;
  dataFilter: (a: IDataFilter) => void;
}

interface IDataFilter {
  id: string;
  value: string;
}

const FilterSearch: FunctionComponent<ISearch> = ({
  id,
  dataValueFilter,
  filterValue,
  dataFilter,
}) => {
  const [search, setSearch] = useState(dataValueFilter?.value ?? "");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    filterValue(value);
    setSearch(value);
    dataFilter({ id, value });
    e.preventDefault();
  };

  const handleClear = (): void => {
    filterValue("");
    setSearch("");
    dataFilter({ id: "", value: "" });
  };

  return (
    <div className={styles.FilterList}>
      <Form>
        <div className={styles.FilterListItem}>
          <Form.Control
            className={styles.SearchInput}
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={handleSearch}
          />
          <MdClear
            className={styles.IconMdClear}
            onClick={handleClear}
            type="button"
          />
        </div>
      </Form>
    </div>
  );
};

export default FilterSearch;
