import React, { FC } from "react";
import Footer from "../../shared/layout/components/Footer";
import { MainLayout } from "pages/components/MainLayout";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const PrivacyPolicyPage: FC = () => {
  return (
    <>
      <MainLayout button />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};
