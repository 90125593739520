import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { useAuth, usePermission } from "../../shared/auth";
import { data, useLead, usePlans } from "../../shared/data";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSelect } from "../../shared/form/FormSelect";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";

import { MainLayout } from "pages/components/MainLayout";

export const LeadApprove: FC<ViewComponentProps> = ({ match: { params } }) => {
  const history = useHistory();
  const { user } = useAuth();
  const lead = useLead(params.id);
  const plans = usePlans();
  const editLeadPermission = usePermission("leads", "edit");
  const handleSubmit: FormCallback = (quote) => {
    const date = new Date();
    const leadId = lead.id;
    const managerId = user.id;
    const orderClosingDate = date.toISOString();
    return data
      .post<any>("/api/opportunities", {
        managerId,
        leadId,
        orderClosingDate,
        quote,
      })
      .then((response) => response.body)
      .then(({ id }) => history.push(`/opportunities/view/${id}`));
  };
  return (
    <MainLayout title="Aprovar Lead em Oportunidade" arrowBack>
      {lead && editLeadPermission && (
        <Card>
          <CardBody>
            <Form value={lead as any}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="client.companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="client.tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="CNPJ">
                    <FormInput name="client.cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Quantidade de Titulares">
                    <FormInput name="client.numberHolders" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Ramo de Negócios">
                    <FormInput name="client.lineOfBusiness" />
                  </FormGroup>
                </div>
                <div className="col-md-7">
                  <FormGroup label="Link do site">
                    <FormInput name="client.webSiteUrl" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Telefone">
                    <FormInput name="client.phone" />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <h4 className="text-primary">ENDEREÇO</h4>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Rua">
                    <FormInput name="client.street" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Número">
                    <FormInput name="client.number" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Complemento">
                    <FormInput name="client.complement" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Bairro">
                    <FormInput name="client.neighborhood" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Cidade">
                    <FormInput name="client.city" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="UF">
                    <FormInput name="client.state" />
                  </FormGroup>
                </div>
              </div>
            </Form>
            <hr />
            <Form onSubmit={handleSubmit}>
              <h4 className="mb-3">Informações da Quote</h4>
              <div className="row">
                <div className="col-md-8">
                  <FormGroup label="Plano">
                    {plans && (
                      <FormSelect
                        name="planId"
                        options={plans.map((p) => ({
                          label: p.name,
                          value: p.id,
                        }))}
                      />
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup label="Desconto">
                    <FormInput name="discount" mask="percent" />
                  </FormGroup>
                </div>
              </div>
              <div className="text-right">
                <FormSubmit>APROVAR</FormSubmit>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
