export enum ResaleStatus {
  Registered = 1,
  Approved = 2,
  Disapproved = 3
}

export const EResaleStatus = new Map<ResaleStatus, string>([
  [ResaleStatus.Registered, 'Registrada'],
  [ResaleStatus.Approved, 'Aprovada'],
  [ResaleStatus.Disapproved, 'Rejeitada']
])