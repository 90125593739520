import React, { FC } from "react";
import makeStyles from "@material-ui/styles/makeStyles";

import { useAuth } from "../../shared/auth";
import { Button } from "../../shared/layout/bootstrap/button/Button";
interface PageHeaderProps {
  transparent?: boolean;
}

const useStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    flex: "1",
    marginLeft: 16,
  },
  button: {
    color: "black",
  },
  right: {
    marginLeft: "auto",
    marginRight: -12,
  },
});

// function getPermissions(userType: string) {
//   console.log(userType);

//   const permissions = {
//     clients: ["commercialManager", "contact", " "],
//     commissions: ["resale"],
//     contacts: ["resale"],
//     leads: ["accountManager", "commercialManager", "contact", "resale"],
//     opportunities: ["accountManager", "commercialManager", "contact", "resale"],
//     resales: ["channelManager"],
//     subscriptions: ["commercialManager", "contact", "resale"],
//   };

//   return Object.entries(permissions)
//     .filter(([types]) => {
//       console.log(types);

//       if (Array.isArray(types)) {
//         console.log("types: ", types);
//         console.log("userType: ", userType);
//         console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

//         console.log("entrou", types.includes(userType));

//         return types.includes(userType);
//       } else {
//         return types;
//       }
//     })
//     .map(([permission]) => permission);
// }

export const PageHeader: FC<PageHeaderProps> = () => {
  const { loggedIn, user } = useAuth();
  const classes = useStyles();

  const permissions = user?.group.map((e) => {
    return e.name;
  });

  if (loggedIn) {
    return (
      <div className={classes.toolbar}>
        {permissions.includes("Administrator") && (
          <Button className={classes.button} to="/resales">
            Canais
          </Button>
        )}
        {permissions.includes("Administrator") && (
          <Button className={classes.button} to="/approve/leads">
            Leads
          </Button>
        )}

        {permissions.includes("Canal") && (
          <Button className={classes.button} to="/leads">
            Leads
          </Button>
        )}
        {permissions.includes("Canal") && (
          <Button className={classes.button} to="/opportunities">
            Oportunidades
          </Button>
        )}
        {/* {permissions.includes("Canal") && (
          <Button className={classes.button} to="/training">
            Treinamento
          </Button>
        )} */}

        <div className={classes.right}>
          {permissions.includes("Administrator") && (
            <Button className={classes.button} to="/profile">
              Meu perfil
            </Button>
          )}

          <Button className={classes.button} to="/logout">
            Sair
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
