import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { usePermission } from "../../shared/auth";
import { data, useOpportunity, usePlans } from "../../shared/data";
import { Form, FormCallback } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { FormSelect } from "../../shared/form/FormSelect";
import { FormSubmit } from "../../shared/form/FormSubmit";
import { Card } from "../../shared/layout/bootstrap/card/Card";
import { CardBody } from "../../shared/layout/bootstrap/card/CardBody";

import { MainLayout } from "pages/components/MainLayout";

export const OpportunityQuote: FC<ViewComponentProps> = ({
  match: { params },
}) => {
  const history = useHistory();
  const opportunity = useOpportunity(params);
  const plans = usePlans();
  const editOpportunityPermission = usePermission("opportunities", "edit");
  const handleSubmit: FormCallback = (value) => {
    return data
      .post(`/api/opportunities/quote?opportunityId=${opportunity.id}`, value)
      .then(() => history.push(`/opportunities/view/${opportunity.id}`));
  };
  const currentQuote = opportunity?.quotes?.find((quote) => {
    return (
      opportunity.quotes.findIndex(
        (current) => current.createdAt >= quote.createdAt
      ) >= 0
    );
  });
  return (
    <MainLayout title="Adicionar Quote à Oportunidade" arrowBack>
      {opportunity && (
        <Card>
          <CardBody>
            <Form readOnly value={opportunity.lead}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="client.companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="client.tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="CNPJ">
                    <FormInput name="client.cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Quantidade de Titulares">
                    <FormInput name="client.numberHolders" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Ramo de Negócios">
                    <FormInput name="client.lineOfBusiness" />
                  </FormGroup>
                </div>
                <div className="col-md-7">
                  <FormGroup label="Link do site">
                    <FormInput name="client.webSiteUrl" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Telefone">
                    <FormInput name="client.phone" />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <h4 className="text-primary">ENDEREÇO</h4>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Rua">
                    <FormInput name="client.street" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Número">
                    <FormInput name="client.number" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Complemento">
                    <FormInput name="client.complement" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Bairro">
                    <FormInput name="client.neighborhood" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Cidade">
                    <FormInput name="client.city" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="UF">
                    <FormInput name="client.state" />
                  </FormGroup>
                </div>
              </div>
            </Form>
            <h5 className="text-primary mt-3 mb-2">DADOS DA NOVA QUOTE</h5>
            {editOpportunityPermission && (
              <div className="col-md-4">
                {currentQuote?.plan?.name && (
                  <p className="mt-2 mb-2">
                    <strong>Plano Atual: </strong> {currentQuote.plan.name}
                  </p>
                )}
                <Form
                  onSubmit={handleSubmit}
                  value={{ planId: currentQuote?.plan?.id || "" }}
                >
                  <FormGroup label="Plano">
                    {plans && (
                      <FormSelect
                        name="planId"
                        options={plans.map((p) => ({
                          label: p.name,
                          value: p.id,
                        }))}
                      />
                    )}
                  </FormGroup>
                  <FormGroup label="Desconto">
                    <FormInput name="discount" mask="percent" />
                  </FormGroup>
                  <div style={{ textAlign: "right" }}>
                    <FormSubmit>Confirmar</FormSubmit>
                  </div>
                </Form>
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
