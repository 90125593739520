import React, { FC } from "react";

import { EContactStatus } from "../../shared/data/enums";
import { useContacts } from "../../shared/data/hooks/contacts";
import { Button } from "../../shared/layout/bootstrap/button/Button";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";
import { formatPhone } from "../../shared/util/format";

import { MainLayout } from "pages/components/MainLayout";

export const ContactList: FC = () => {
  const contacts = useContacts();
  const action = (
    <Button to="/contacts/add" variant="primary">
      Cadastrar Contato
    </Button>
  );
  return (
    <MainLayout title="Listagem de Contatos" action={action}>
      <Table hover>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact, i) => (
            <TableRow key={i}>
              <TableCell>{contact.name}</TableCell>
              <TableCell>{contact.email}</TableCell>
              <TableCell>{formatPhone(contact.phoneNumber)}</TableCell>
              <TableCell>{EContactStatus.get(contact.status)}</TableCell>
              <TableCell>
                <IconButton to={`/contacts/view/${contact.id}`}>
                  <IconEyeFill />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
