import { data } from '../data/data-service';
import {
  setToken,
  // getRefreshToken,
  setRefreshToken,
  setUser,
  // getToken,
} from '../data/auth';

export function authenticateUser(url: string, form: any) {
  return data
    .post<any>(url, form)
    .then((response) => response.body)
    .then(({ accessToken, refreshToken, error, user }) => {
      if (error) {
        
        throw new Error(error);
      }
      setToken(accessToken);
      setRefreshToken(refreshToken);
      setUser(user);
    });
}

export function authenticateToken() {
  return data
    .get('/teste3')
    // .then((response) => console.log('resposta:', response.body))
    //.then((user) => setUser(user))
    //.catch(authenticateRefreshToken) -> reativar quando estiver utilizando o refrashToken
    .catch((err) => {
      if (err.status === 401) {
        if (err.body.message === 'Token invalid') {
          setToken(null);
          setRefreshToken(null);

          window.location.href = '#login';
          window.location.reload();
        }    
      }
    });
}

// function authenticateRefreshToken() {
//   const token = encodeURIComponent(getToken()!);
//   const refreshToken = encodeURIComponent(getRefreshToken()!);
//   return data
//     .post<any>(
//       `/api/accounts/refresh?token=${token}&refreshToken=${refreshToken}`,
//       {}
//     )
//     .then((response) => response.body)
//     .then(({ accessToken, refreshToken, ...user }) => {
//       setToken(accessToken);
//       setRefreshToken(refreshToken);
//       setUser(user);
//     });
// }
