import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function usePlans() {
  const [ plans, setPlans ] = useState<Plan[]>()
  useEffect(() => {
    data.get<Plan[]>('/api/plans')
      .then(response => response.body)
      .then(plans => setPlans(plans!))
  }, [])
  return plans
}