import React, { FC } from "react";

import { usePermission } from "../../../shared/auth";
import { useResales } from "../../../shared/data";
import { Button } from "../../../shared/layout/bootstrap/button/Button";
import { IconButton } from "../../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../shared/layout/bootstrap/table";

import { MainLayout } from "pages/components/MainLayout";

export const ResaleList: FC = () => {
  const resales = useResales();

  const action = usePermission("resales", "add") && (
    <Button to="/resales/add" variant="primary">
      Cadastrar Revenda
    </Button>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "PENDENTE":
        return "#FF9E15";
      case "APROVADO":
        return "#35DC3E";
      case "REPROVADO":
        return "#C3C3C3";
      default:
        return "";
    }
  };
  return (
    <MainLayout title="Listagem de Canais" action={action}>
      <Table hover>
        {resales && (
          <TableHead>
            <TableCell>Visualizar</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Nome Fantasia</TableCell>
            {/* <TableCell>CNPJ</TableCell> */}
            <TableCell>Cidade</TableCell>
            <TableCell>UF</TableCell>
            <TableCell>Status</TableCell>
          </TableHead>
        )}
        <TableBody>
          {resales ? (
            resales.map((resale, i) => (
              <TableRow key={i}>
                <TableCell>
                  <IconButton to={`/resales/view/${resale.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>
                <TableCell>{resale.name}</TableCell>
                <TableCell>{resale.trading_name}</TableCell>
                {/* <TableCell>{resale.register_number}</TableCell> */}
                <TableCell>{resale.city}</TableCell>
                <TableCell>{resale.state}</TableCell>
                <TableCell>
                  <span style={{ color: getStatusColor(resale.status) }}>
                    {resale.status}
                  </span>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>
                <h6>Carregando...</h6>
              </TableCell>
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
