import React, { ChangeEvent, FC, useContext, useState } from "react";

import { FormContext, FormValue } from "./FormContext";

interface FormSelectOption {
  label: string;
  value: number | string;
}

interface FormSelectProps {
  label?: string;
  name?: string;
  onChange?: (_: FormValue) => void;
  options?: FormSelectOption[];
}

export const FormSelect: FC<FormSelectProps> = ({
  children,
  onChange,
  name,
  options,
}) => {
  const form = useContext(FormContext);
  const [value, setValue] = useState<string>("");
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    form.set?.(name, event.target.value);
    onChange?.(event.target.value);
    setValue(event.target.value);
  };
  return (
    <select className="custom-select" onChange={handleChange} value={value}>
      <option value="">Selecione</option>
      {options
        ? options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        : children}
    </select>
  );
};
