import React, { FC } from "react";

import { useOrders } from "../../shared/data";
import { EOrderStatus, EPaymentTypes } from "../../shared/data/enums";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";
import { formatDate } from "../../shared/util/format";

import { MainLayout } from "pages/components/MainLayout";

export const OrderList: FC = () => {
  const orders = useOrders();
  return (
    <MainLayout title="Listagem de Assinaturas">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Empresa</TableCell>
            <TableCell>Código da Assinatura</TableCell>
            <TableCell>Tipo de Pagamento</TableCell>
            <TableCell>DPO</TableCell>
            <TableCell>Criação</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders &&
            orders.map((order) => (
              <TableRow key={order.id}>
                {/* <TableCell>{order.opportunity.lead.client.tradingName}</TableCell> */}
                <TableCell>{order.code}</TableCell>
                <TableCell>{EPaymentTypes.get(order.paymentType)}</TableCell>
                <TableCell>{order.dpoName}</TableCell>
                <TableCell>{formatDate(order.orderDate)}</TableCell>
                <TableCell>{EOrderStatus.get(order.status)}</TableCell>
                <TableCell>
                  <IconButton to={`/orders/view/${order.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
