import React, { FC } from "react";

import { useCommission } from "../../shared/data";
import { Form } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { Card, CardBody } from "../../shared/layout/bootstrap/card";
import { formatCurrency, formatDate } from "../../shared/util/format";
import { MainLayout } from "pages/components/MainLayout";

function latestQuote(order: undefined | Order, prop: string) {
  const [latest] = order?.opportunity?.quotes?.slice(-1);
  return latest?.[prop];
}

export const CommissionView: FC<ViewComponentProps> = ({ match }) => {
  const commission = useCommission(match.params.id);
  return (
    <MainLayout title="Visualizar Comissão" arrowBack>
      {commission && (
        <Card>
          <CardBody>
            <Form readOnly value={commission as any}>
              <div className="row">
                <div className="col-md-3">
                  <FormGroup label="Código da Assinatura">
                    <FormInput name="order.code" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Código da Comissão">
                    <FormInput name="code" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Valor Título">
                    <FormInput
                      value={formatCurrency(
                        latestQuote(commission.order, "finalValue")
                      )}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Valor Comissão">
                    <FormInput value={formatCurrency(commission.value)} />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Expiração">
                    <FormInput value={formatDate(commission.expirationDate)} />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Status NF-e">
                    <FormInput
                      value={ECommissionStatus.get(commission.invoiceStatus)}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Status Pagamento">
                    <FormInput
                      value={ECommissionStatus.get(commission.paymentStatus)}
                    />
                  </FormGroup>
                </div>
              </div>
            </Form>
            <hr />
            <Form readOnly value={commission.order.opportunity.lead}>
              <div className="row">
                <div className="col-12">
                  <h5 className="text-primary mt-2 mb-3">
                    INFORMAÇÕES DA ASSINATURA
                  </h5>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="client.companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="client.tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="CNPJ">
                    <FormInput name="client.cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Quantidade de Titulares">
                    <FormInput name="client.numberHolders" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Ramo de Negócios">
                    <FormInput name="client.lineOfBusiness" />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <h5 className="text-primary mt-4 mb-3">ENDEREÇO</h5>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Rua">
                    <FormInput name="client.street" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Número">
                    <FormInput name="client.number" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Complemento">
                    <FormInput name="client.complement" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Bairro">
                    <FormInput name="client.neighborhood" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Cidade">
                    <FormInput name="client.city" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="UF">
                    <FormInput name="client.state" />
                  </FormGroup>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
