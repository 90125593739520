import React, { FC } from 'react'

import { Button, ButtonProps } from '../layout/bootstrap/button/Button'
import { classNames } from '../util/class-names'

import { useFormLoading } from './FormContext'

import styles from './FormSubmit.module.scss'

interface FormSubmitProps extends ButtonProps {
  loading?: boolean
}

export const FormSubmit: FC<FormSubmitProps> = ({className, ...props}) => {
  const loading = useFormLoading()
  return (
    <div className={classNames(styles.button, loading && styles.loading, props.block && 'd-block', className)}>
      <div className={styles.loader}>
        <div className={`${styles.spinner} spinner-border text-primary`} />
      </div>
      <Button disabled={loading} variant="primary" type="submit" {...props} />
    </div>
  )
}