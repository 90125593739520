import React from "react";
import { css } from "@emotion/react";

import { ClipLoader } from "react-spinners";

//import { Container, Card } from "./styles";
import styles from "./index.module.scss";

interface ILoadingMaskProps {
  text?: string;
}

const override = css`
  display: flex;
`;

const LoadingMask: React.FC<ILoadingMaskProps> = ({ text }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        {text ? <h1>{text}</h1> : <h1>Carregando ... </h1>}{" "}
        <ClipLoader css={override} color="#19b2ff" loading size={24} />{" "}
      </div>
    </div>
  );
};

export default LoadingMask;
