import React, { FC } from 'react'

import { classNames } from '../../../util/class-names'

interface ColProps {
  className?: string
  size?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const Col: FC<ColProps> = ({className, size, sm, md, lg, xl, ...props}) => {
  const classes = classNames(
    className,
    size && `col-${size}`,
    sm && `col-sm-${sm}`,
    md && `col-md-${md}`,
    lg && `col-lg-${lg}`,
    xl && `col-xl-${xl}`
  )
  return (
    <div className={classes} {...props} />
  )
}
