export function formatString(value: string, format: string) {
  value = value.replace(/[^0-9]/, '')
  return Array.from(format).reduce((current, char) => {
    if (char === '0') {
      const val = value.charAt(0)
      value = value.substr(1)
      return `${current}${val}`
    } else {
      return `${current}${char}`
    }
  }, '')
}

export function formatCnpj(cnpj: string) {
  return formatString(cnpj, '00.000.000/0000-00')
}

export function formatCpf(cpf: string) {
  return formatString(cpf, '000.000.000-00')
}

export function formatCurrency(value: string | number, digits = 2) {
  return 'R$ ' + Number(value).toLocaleString('pt-BR', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  })
}

export function formatDate(d: string) {
  // return new Date(date).toLocaleDateString('pt-BR')
  const date = new Date(d);
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate.toLocaleDateString('pt-BR');  
}

export function formatDateTime(date: string) {
  return new Date(date).toLocaleString('pt-BR')
}

export function formatPercent(value: string | number) {
  return Number(value).toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }) + '%'
}

export function formatPhone(phone: string) {
  phone = phone.replace(/[0-9]/, '')
  if (phone.length === 11) {
    return formatString(phone, '(00) 00000-0000')
  }
  return formatString(phone, '(00) 0000-0000')
}

