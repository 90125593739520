import React, { FC } from 'react'

import { classNames } from '../../../util/class-names'

interface RowProps {
  className?: string
}

export const Row: FC<RowProps> = ({className, ...props}) => (
  <div className={classNames(className, 'row')} {...props} />
)