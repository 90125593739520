import React, { FC, Fragment } from "react";

import { useClient } from "../../shared/data";

import { Form } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";

import { MainLayout } from "pages/components/MainLayout";

export const ClientView: FC<ViewComponentProps> = ({ match: { params } }) => {
  const client = useClient(params.id);
  return (
    <MainLayout title="Visualização de Cliente" arrowBack>
      {client && (
        <Form value={client as any}>
          <div className="card">
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-7">
                    <FormGroup label="Razão Social">
                      <FormInput name="companyName" />
                    </FormGroup>
                  </div>
                  <div className="col-md-5">
                    <FormGroup label="Nome Fantasia">
                      <FormInput name="tradingName" />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup label="CNPJ">
                      <FormInput name="cnpj" />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup label="Telefone">
                      <FormInput name="phone" />
                    </FormGroup>
                  </div>
                  <div className="col-md-2">
                    <FormGroup label="Nº Titulares">
                      <FormInput name="numberHolders" />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup label="Ramo de Negócios">
                      <FormInput name="lineOfBusiness" />
                    </FormGroup>
                  </div>
                  <div className="col-md-8">
                    <FormGroup label="Endereço">
                      <FormInput name="street" />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup label="Número">
                      <FormInput name="number" />
                    </FormGroup>
                  </div>
                  <div className="col-md-5">
                    <FormGroup label="Bairro">
                      <FormInput name="neighborhood" />
                    </FormGroup>
                  </div>
                  <div className="col-md-5">
                    <FormGroup label="Cidade">
                      <FormInput name="city" />
                    </FormGroup>
                  </div>
                  <div className="col-md-2">
                    <FormGroup label="UF">
                      <FormInput name="state" />
                    </FormGroup>
                  </div>
                  {(client as any).seller && (
                    <Fragment>
                      <div className="col-12">
                        <h4 className="mt-3 mb-2">Revenda</h4>
                      </div>
                      <div className="col-md-4">
                        <FormGroup label="Nome">
                          <FormInput name="seller.name" />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup label="Razão Social">
                          <FormInput name="seller.resale.companyName" />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup label="Nome Fantasia">
                          <FormInput name="seller.resale.tradingName" />
                        </FormGroup>
                      </div>
                      <div className="col-md-8">
                        <FormGroup label="Endereço">
                          <FormInput name="seller.resale.street" />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup label="Número">
                          <FormInput name="seller.resale.number" />
                        </FormGroup>
                      </div>
                      <div className="col-md-5">
                        <FormGroup label="Bairro">
                          <FormInput name="seller.resale.neighborhood" />
                        </FormGroup>
                      </div>
                      <div className="col-md-5">
                        <FormGroup label="Cidade">
                          <FormInput name="seller.resale.city" />
                        </FormGroup>
                      </div>
                      <div className="col-md-2">
                        <FormGroup label="UF">
                          <FormInput name="seller.resale.state" />
                        </FormGroup>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </MainLayout>
  );
};
