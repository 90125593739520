import React, { FC, ReactNode } from 'react'

import { classNames } from '../../../util/class-names'

interface InputGroupProps {
  className?: string
  append?: ReactNode
  prepend?: ReactNode
}

export const InputGroup: FC<InputGroupProps> = ({append, className, children, prepend}) => (
  <div className={classNames('input-group', className)}>
    {prepend && (
      <div className="input-group-prepend">
        {prepend}
      </div>
    )}
    {children}
    {append && (
      <div className="input-group-append">
        {append}
      </div>
    )}
  </div>
)