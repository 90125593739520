import React from "react";
import { Accordion } from "react-bootstrap";

export const PrivacyPolicy = () => {
  return (
    <>
      <h1 className="text-center pt-5 pb-5">Política de Privacidade</h1>
      <Accordion className="px-5 pb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Objetivo</Accordion.Header>
          <Accordion.Body>
            <div>
              Esta Política estabelece as orientações gerais para a proteção de
              dados pessoais dentro do ambiente corporativo da
              <span>
                {" "}
                DNASec Serviços Profissionais e Consultoria Ltda (“DNASec”){" "}
              </span>
              e todos os seus Parceiros no Brasil e no exterior (BoxGroup,
              SecurityBox, NetworkBox, DevBox e AcademyBox), uma vez que na
              execução de suas operações coleta, manuseia e armazena informações
              que podem estar relacionadas a pessoas físicas identificadas e/ou
              identificáveis (“Dados Pessoais”), com vistas a:{" "}
            </div>
            <div>
              <ul>
                <li>
                  Estar em conformidade com as leis e regulamentações aplicáveis
                  de proteção de Dados Pessoais e seguir as melhores práticas;{" "}
                </li>
                <li>
                  Proteger os direitos dos Integrantes, clientes, fornecedores e
                  parceiros contra os riscos de violações de Dados Pessoais;{" "}
                </li>
                <li>
                  Ser transparente com relação aos procedimentos da{" "}
                  <span>DNASec</span> no Tratamento de Dados Pessoais; e{" "}
                </li>
                <li>
                  Promover a conscientização em toda a <span>DNASec</span> em
                  relação à proteção de Dados Pessoais e questões de
                  privacidade.{" "}
                </li>
              </ul>
            </div>
            <div>
              Em particular, esta política exige que a equipe garanta que o DPO
              (Data Protection Officer) ou (Encarregado de Proteção de
              Dados), seja consultado antes que qualquer nova atividade
              significativa de processamento de dados seja iniciada para
              garantir que as etapas de conformidade relevantes sejam tratadas.
            </div>

            <div>
              <span>A DNASec</span>, está totalmente comprometida em garantir a
              implementação contínua e eficaz desta política e espera que todos
              os funcionários da <span>DNASec</span> compartilhem esse
              compromisso. Qualquer violação desta política será levada a sério
              e poderá resultar em uma ação disciplinar.{" "}
            </div>
            <div>
              Essa política foi aprovada pelo Diretor de Segurança,{" "}
              <i>
                “Chief Information Security Officer (CISO)”,”Data Protection
                Expert (DPE)”.
              </i>{" "}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Abrangência</Accordion.Header>
          <Accordion.Body>
            <div>
              Esta Política é aplicável à <span>DNASec</span> e a todos os seus{" "}
              <span>Parceiros</span>, tanto no Brasil quanto no exterior, e a
              todos os Integrantes que tenham acesso a quaisquer Dados Pessoais
              detidos pela <span>DNASec</span> ou em seu nome. Procedimentos
              adicionais podem ser criados de acordo com exigência da legislação
              local.{" "}
            </div>
            <div>
              Qualquer legislação aplicável nas diferentes regiões nas quais a
              <span> DNASec</span> atua devem prevalecer caso estejam ou venham
              estar em conflito com esta Política.{" "}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Referências </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  Código de Conduta da <span>DNASec</span>{" "}
                </li>
                <li>Política Global do Sistema de Conformidade </li>
                <li>Política de Segurança da Informação </li>
                <li>Procedimentos de Auditoria Interna </li>
                <li>
                  <i>General Data Protection Regulation</i> (“GDPR”) na Europa{" "}
                </li>
                <li>Lei Geral de Proteção de Dados (“LGPD”) no Brasil </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Definições </Accordion.Header>
          <Accordion.Body>
            <div>
              Seguem abaixo as definições dos termos utilizados nesta Política
              iniciados com letra maiúscula.{" "}
            </div>

            <div>
              <span>“Anonimização”:</span> Processo e técnica por meio dos quais
              um dado perde a possibilidade de associação, direta ou indireta, a
              um indivíduo. Dado anonimizado não é considerado Dado Pessoal.{" "}
            </div>

            <div>
              <span>“DNASec” ou “Parceiros”:</span> DNASec e todas os seus
              Parceiros no Brasil e no exterior.{" "}
            </div>

            <div>
              <span>“CTA”</span> ou <span>“Conselho”</span> ou{" "}
              <span>“Conselho Técnico e Administrativo”</span>: Conselho Técnico
              de Administração da DNASec.{" "}
            </div>

            <div>
              <span>“Comitê de Conformidade”:</span> Comitê de Conformidade, de
              apoio ao Conselho Técnico e Administrativo da DNASec.{" "}
            </div>

            <div>
              <span> “Comitê de Privacidade”:</span> Comitê global
              multidisciplinar consultivo formado por Líderes das áreas do
              Jurídico, Conformidade, Gestão de Riscos, Segurança da Informação
              e Pessoas e Cultura, assim como pelos representantes de cada área
              relevante nas regiões para discutir temas relevantes e críticos de
              Segurança da Informação e Privacidade de Dados.{" "}
            </div>

            <div>
              <span>“Conformidade”</span> ou <span>“Área de Conformidade”</span>
              : o responsável local por Conformidade e seus integrantes.{" "}
            </div>

            <div>
              <span>“Consentimento”:</span> Manifestação livre, informada e
              inequívoca pela qual o Titular concorda com o Tratamento de seus
              Dados Pessoais para uma finalidade determinada.{" "}
            </div>

            <div>
              <span>“Controlador”:</span> Pessoa jurídica, de direito público ou
              privado, a quem competem as decisões referentes ao Tratamento de
              Dados Pessoais.{" "}
            </div>

            <div>
              <span>“Dado(s) Pessoal(is)”:</span> Qualquer informação relativa a
              uma pessoa singular identificada ou identificável, que pode ser
              identificada, direta ou indiretamente, por referência a um
              identificador como nome, número de identificação, dados de
              localização, identificador on-line ou a um ou mais fatores
              específicos a identidade física, fisiológica, genética, mental,
              econômica, cultural ou social dessa pessoa natural.{" "}
            </div>

            <div>
              <span>“Dado(s) Pessoal(is) Sensível(is)”:</span> Todo Dado Pessoal
              que pode gerar qualquer tipo de discriminação, como por exemplo os
              dados sobre origem racial ou étnica, convicção religiosa, opinião
              política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à
              vida sexual, dado genético ou biométrico.{" "}
            </div>

            <div>
              <span>“Data Protection Expert (DPE)”:</span> Especialista no tema
              de Proteção de Dados local/regional, com as atribuições e
              responsabilidades de um DPO, porém, com reduzido ou nenhum poder
              decisório.{" "}
            </div>

            <div>
              <span>"Política de Segurança da Informação":</span> Diretrizes
              corporativas globais da DNASec sobre Segurança da Informação, que
              podem ser alteradas periodicamente.{" "}
            </div>

            <div>
              <span> “Documentação(ões) Orientadora(s)”:</span> Documento(s)
              formal(is) da DNASec que fornece(m) conteúdo sobre decisões,
              regras e orientações corporativas que são vitais para direcionar o
              trabalho da DNASec com legitimidade, rastreabilidade e
              aplicabilidade e deve ser observado e praticado por um certo
              universo definido de Integrantes.{" "}
            </div>

            <div>
              <span>“Encarregado de Proteção de Dados”</span> ou{" "}
              <i>
                <span>“Data Protection Officer (“DPO”)”:</span>
              </i>{" "}
              O indivíduo designado como encarregado formal/oficial de proteção
              de dados, conforme previsto nas leis de proteção de dados, tais
              como GDPR e LGPD, para um determinado território. O DPO pode ser
              um integrante ou uma pessoa terceirizada.{" "}
            </div>

            <div>
              <span>“GDPR”:</span> Regulamento (UE) 2016/679 do Parlamento
              Europeu de 27 de abril de 2016, relativo à proteção das pessoas
              singulares no que diz respeito ao Tratamento de Dados Pessoais e à
              livre circulação desses dados e que revoga a Diretiva 95 / 46 / CE
              (Regulamento Geral de Proteção de Dados).{" "}
            </div>

            <div>
              <span>“Influenciador(es) de Privacidade”:</span> Ponto focal nas
              áreas de Negócio ou Apoio ou áreas que requeiram atenção
              específica a fim de facilitar o contato com o Líder de Privacidade
              Corporativo e/ou DPE para com a área e vice-versa, servindo também
              como facilitador de treinamentos e comunicações de privacidade,
              das áreas com maior acesso a Dados Pessoais, sem poder decisório.{" "}
            </div>

            <div>
              <span>“Integrante(s)”:</span> Funcionários/empregados que
              trabalham na <span>DNASec</span>
              em todos os níveis, incluindo executivos, conselheiros, diretores,
              estagiários e aprendizes (conforme aplicabilidade nas localidades
              geográficas).{" "}
            </div>

            <div>
              <span>“Jurídico”:</span> Área responsável pela gestão dos
              contratos celebrados entre a <span>DNASec</span> e Terceiros.{" "}
            </div>

            <div>
              <span>“LFPDPPP”:</span> Legislação Mexicana aprovada em 2010. Lei
              Federal de Proteção de Dados Pessoais em Possessão de
              Particulares, as suas disposições aplicam-se a todas as pessoas
              singulares ou coletivas que realizam o Tratamento de Dados
              Pessoais no exercício aplicável das suas atividades.{" "}
            </div>

            <div>
              <span>“LGPD”:</span> Legislação brasileira nº 13.709/2018,
              comumente conhecida como Lei Geral de Proteção de Dados Pessoais,
              que regula as atividades de Tratamento de Dados Pessoais e que
              também altera os artigos 7º e 16 do Marco Civil da Internet.{" "}
            </div>

            <div>
              <span>“Líder(es)”:</span> Todo Integrante que lidera uma equipe.{" "}
            </div>

            <div>
              <span>“Líder de Privacidade Corporativo”:</span> é um Líder
              independente da Diretoria da <span>DNASec</span> a fim de ser
              imparcial na salvaguarda dos direitos dos Titulares de Dados
              Pessoais que são tratados pela <span>DNASec</span>.{" "}
            </div>

            <div>
              <span>“LT DNASec”:</span> Líder de Técnico da <span>DNASec</span>;
              o Líder global da <span>DNASec</span>, conhecido no Brasil como LT{" "}
              <span>DNASec</span> e no exterior como{" "}
              <i>Chief Information Security Officer ("CISO")</i> da DNASec.{" "}
            </div>

            <div>
              <span>“Operador”:</span> Pessoa natural ou jurídica, de direito
              público ou privado, que realiza o Tratamento de Dados Pessoais em
              nome do Controlador.{" "}
            </div>

            <div>
              <span>"Política":</span> essa Política de Proteção de Dados da{" "}
              <span>DNASec</span>.{" "}
            </div>

            <div>
              <span>"Política Global do Sistema de Conformidade":</span> A
              Política Global de Conformidade Corporativa da DNASec, que pode
              ser alterada periodicamente.{" "}
            </div>

            <div>
              <span>“Plano de Ação (PA)”:</span> Acordo entre o Líder e o
              Liderado que define as responsabilidades do Integrante e o
              compromisso do Líder com o acompanhamento, a avaliação e a decisão
              com relação ao Liderado de acordo com seu desempenho.{" "}
            </div>

            <div>
              <span>“Pseudonimização”:</span> Processos e técnicas por meio dos
              quais um dado tem sua possibilidade de associação dificultada. O
              dado Pseudonimizado é considerado Dado Pessoal tendo em vista a
              possibilidade de associação desse dado a uma pessoa natural.{" "}
            </div>

            <div>
              <span>“R-Conformidade”:</span> O executivo sênior Líder da função
              de <i>Compliance</i>
              da <span>DNASec</span>, conhecido no Brasil como R-Conformidade e
              no exterior, <i>Chief Compliance Officer</i> ("CCO") da{" "}
              <span>DNASec</span>.{" "}
            </div>

            <div>
              <span>“Segurança da Informação”</span> ou <span>“SI”</span>: Área
              responsável por proteger a integridade, disponibilidade e
              confidencialidade dos sistemas de TI e deve implementar as medidas
              adequadas para alcançar este objetivo, sendo o apoio técnico do
              Líder de Privacidade Corporativo e responsável pelas questões
              relacionadas às medidas técnicas e administrativas.{" "}
            </div>

            <div>
              <span>“Sociedade(s) Controlada(s)”:</span> sociedades nas quais a{" "}
              <span>DNASec</span>, diretamente ou por meio de outras Sociedades
              Controladas, detêm direitos que lhe asseguram, de forma
              permanente, a prevalência nas deliberações societárias e o poder
              de eleger a maioria dos administradores ou conselheiros.{" "}
            </div>

            <div>
              <span>“Terceiro(s)” ou “Parceiro(s)”:</span> Qualquer pessoa,
              física ou jurídica, que atue em nome, no interesse ou para o
              benefício da <span>DNASec</span>, preste serviços ou forneça
              outros bens, assim como Parceiros comerciais que prestem serviços
              à <span>DNASec</span>, diretamente relacionados à obtenção,
              retenção ou facilitação de negócios, ou para a condução de
              assuntos da <span>DNASec</span>, incluindo, sem limitação,
              quaisquer distribuidores, agentes, corretores, despachantes,
              intermediários, Parceiros de cadeia de suprimentos, consultores,
              revendedores, contratados e outros prestadores de serviços
              profissionais.{" "}
            </div>

            <div>
              <span>“Titular(es) de Dados”:</span> Pessoa natural singular
              identificada ou identificável a quem se refere um Dado Pessoal
              específico.{" "}
            </div>

            <div>
              <span>“Tratamento de Dados Pessoais”</span> ou{" "}
              <span>“Tratamento”</span>: Qualquer operação ou conjunto de
              operações efetuadas sobre Dados Pessoais ou sobre conjuntos de
              Dados Pessoais, por meios automatizados ou não automatizados, tais
              como a coleta, o registo, a organização, a estruturação, a
              conservação, a adaptação ou alteração, a recuperação, a consulta,
              a utilização, a divulgação por transmissão, difusão ou qualquer
              outra forma de disponibilização, a comparação ou interconexão, a
              limitação, o apagamento ou a destruição.
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Atribuições e Responsabilidades </Accordion.Header>
          <Accordion.Body>
            <div>
              {" "}
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Conselho Técnico Administrativo (“CTA” ou “Conselho”)
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>Aprovar esta Política e suas futuras alterações; e </li>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Comitê de Conformidade (“CC”)
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Revisar e recomendar a aprovação desta Política e suas
                      alterações ao CTA;{" "}
                    </li>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Definir e aprovar a estrutura de governança para os
                      assuntos de privacidade e proteção de dados;{" "}
                    </li>
                    <li>
                      Fazer o monitoramento permanente e efetivo da
                      implementação das iniciativas de privacidade, incluindo os
                      eventos relacionados a vazamento de Dados Pessoais e as
                      decisões do Comitê de Privacidade;{" "}
                    </li>
                    <li>
                      Garantir que no orçamento da Área de Conformidade, a ser
                      aprovado anualmente pelo CTA, estejam previstos os
                      recursos necessários para a implementação e gerenciamento
                      das iniciativas de privacidade;{" "}
                    </li>
                    <li>
                      Propor ao Comitê de Privacidade a resolução das matérias
                      relativas a eventos de alto risco que forem encaminhadas
                      por este ao CC; e{" "}
                    </li>
                    <li>
                      Reportar ao CTA os eventos relacionados a vazamento de
                      Dados Pessoais e as decisões do Comitê de Privacidade.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Líderes </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais nas
                      atividades de suas respectivas áreas;{" "}
                    </li>
                    <li>
                      Garantir que os requisitos da legislação e regulamentação
                      aplicáveis no país de atuação sejam atendidos, bem como
                      que os seus liderados atuem de acordo com esta Política; e{" "}
                    </li>
                    <li>
                      Revisar e manter atualizado o mapeamento de Dados
                      Pessoais, pelo menos uma vez por ano (ou sempre em caso de
                      mudanças substanciais), junto com a Área de Conformidade
                      responsável.{" "}
                    </li>
                    <li>
                      Garantir que, ao usar Consentimento para o Tratamento de
                      Dados Pessoais, que este seja coletado e gerenciado de
                      forma que a opção dada pelo Titular do Dado seja
                      respeitada e que gere evidências necessárias para
                      apresentação às autoridades ou ao próprio Titular, quando
                      necessário.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Líder de Privacidade Corporativo{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Propor ao CC a revisão e atualização desta Política;{" "}
                    </li>
                    <li>
                      Garantir que a <span>DNASec</span> esteja em conformidade
                      com as leis e regulamentos relacionados à privacidade e
                      proteção de Dados Pessoais, bem como com suas políticas e
                      procedimentos internos relacionados ao tema;{" "}
                    </li>
                    <li>
                      Liderar, coordenar e supervisionar a estratégia de
                      proteção de Dados Pessoais e orientar na implementação das
                      medidas requeridas para estar em conformidade com os
                      requisitos da legislação e da regulamentação aplicáveis de
                      proteção de Dados Pessoais;{" "}
                    </li>
                    <li>
                      Participar e orientar, sob a ótica de privacidade, os
                      projetos corporativos que envolvam Tratamento de Dados
                      Pessoais a fim de validar a aderência aos requisitos da
                      legislação e da regulamentação aplicáveis, além de
                      garantir privacidade como um padrão a ser adotado e a
                      incorporação no desenho das medidas de segurança
                      necessárias;{" "}
                    </li>
                    <li>
                      Realizar treinamentos, programas de conscientização e
                      comunicação do tema de privacidade de Dados Pessoais em
                      toda a <span>DNASec</span>;{" "}
                    </li>
                    <li>
                      Elaborar e manter atualizada a Documentação Orientadora
                      relativa à privacidade que estejam na sua competência;{" "}
                    </li>
                    <li>
                      Monitorar o cumprimento das regras internas de
                      privacidade;{" "}
                    </li>
                    <li>
                      Desenvolver, com o apoio da Área Jurídica, acordos
                      internacionais de transferência de dados, bem como manter
                      atualizados os Dados Pessoais que são transferidos entre
                      as diferentes regiões;{" "}
                    </li>
                    <li>
                      Coordenar a execução de análise de impacto de privacidade
                      de dados (“RIPD”: Relatório de Impacto à Proteção de Dados
                      ou <i>“DPIA”: Data Protection Impact Analysis</i>);{" "}
                    </li>
                    <li>
                      Alinhar periodicamente as definições e critérios com o
                      <i>Data Protection Officer</i> (DPO) e Influenciadores de
                      Privacidade local;{" "}
                    </li>
                    <li>
                      Definir, revisar e atualizar avisos de privacidade;{" "}
                    </li>
                    <li>
                      Conduzir periodicamente avaliações de maturidade da{" "}
                      <span>DNASec</span>
                      em relação às iniciativas de privacidade, identificando
                      melhorias assim como a sua evolução;{" "}
                    </li>
                    <li>
                      Acompanhar e apoiar a implementação dos planos de ação
                      para correção de gaps das iniciativas de privacidade;{" "}
                    </li>
                    <li>
                      Endereçar as solicitações dos Titulares de Dados Pessoais
                      de acordo com a legislação e regulamentação vigente em
                      cada país e com a Documentação Orientadora da{" "}
                      <span>DNASec</span>;{" "}
                    </li>
                    <li>
                      Cooperar e se relacionar com a Autoridade Nacional de
                      Proteção de Dados Pessoais (Brasil); e{" "}
                    </li>
                    <li>
                      Garantir a manutenção das evidências de execução e
                      implementação das iniciativas de privacidade atendendo ao
                      princípio da responsabilização.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>R-Conformidade </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Apoiar administrativamente o DPE e o Líder de Privacidade
                      Corporativo nos treinamentos, campanhas de
                      conscientização, comunicação interna, etc.;{" "}
                    </li>
                    <li>
                      Estabelecer os correspondentes orçamentos para execução
                      das suas atividades do DPO, responsabilizando-se pelo
                      gerenciamento deles;{" "}
                    </li>
                    <li>
                      Aprovar as Documentações Orientadoras de Proteção de Dados
                      Pessoais que estejam na sua competência, alinhados com
                      esta Política; e{" "}
                    </li>
                    <li>
                      Reportar ao CC as preocupações relacionadas à
                      implementação das iniciativas de privacidade.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Comitê de Privacidade </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Promover o conhecimento adequado dos principais
                      stakeholders em relação à importância da proteção de Dados
                      Pessoais e das atividades internas inerentes as
                      iniciativas de privacidade;{" "}
                    </li>
                    <li>
                      Revisar anualmente, ou em prazo menor quando necessário,
                      as iniciativas de privacidade adotadas pela{" "}
                      <span>DNASec</span>;{" "}
                    </li>
                    <li>
                      Discutir e tomar decisões técnicas sobre novas atividades
                      de Tratamento de Dados Pessoais, com base nos relatórios
                      de impacto à proteção de Dados Pessoais;{" "}
                    </li>
                    <li>
                      Decidir sobre as medidas técnicas a serem aplicadas para
                      eventos alto risco, assim como as medidas disciplinares;{" "}
                    </li>
                    <li>
                      Submeter ao CC a resolução sobre as medidas técnicas
                      relativas a eventos de alto risco que não sejam de sua
                      alçada; e{" "}
                    </li>
                    <li>
                      Reportar ao CC os eventos relacionados a vazamento de
                      Dados Pessoais e as suas decisões.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Data Protection Expert (DPE){" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Participar e orientar sob a ótica de privacidade os
                      projetos regionais que envolvam Tratamento de Dados
                      Pessoais a fim de validar a aderência aos requisitos da
                      legislação e da regulamentação aplicáveis, além de
                      garantir privacidade como um padrão e a incorporação no
                      desenho das medidas de segurança necessárias;{" "}
                    </li>
                    <li>
                      Auxiliar operacionalmente o monitoramento do cumprimento
                      das regras internas e manutenção de{" "}
                      <i>KPIs (Key Performance Indicator)</i> relacionados à
                      proteção de dados e privacidade;{" "}
                    </li>
                    <li>
                      Auxiliar na condução periódica de avaliações regionais de
                      maturidade sobre as iniciativas de privacidade,
                      identificando a evolução do programa e os gaps
                      remanescentes e/ou novos;{" "}
                    </li>
                    <li>
                      Apoiar no acompanhamento regional e na implementação dos
                      planos de ação para correção de gaps das iniciativas de
                      privacidade;{" "}
                    </li>
                    <li>
                      Apoiar no preparo dos relatórios de impacto à proteção de
                      Dados Pessoais (RIPD – Relatório de Impacto à Proteção de
                      Dados ou <i>DPIA – Data Protection Impact Analysis</i>) e
                      na tomada de decisão dos projetos regionais garantido o
                      alinhamento com os requerimentos desta Política;{" "}
                    </li>
                    <li>
                      Monitorar as requisições regionais dos Titulares de Dados
                      Pessoais a fim de garantir que sejam respondidas dentro do
                      prazo;{" "}
                    </li>
                    <li>
                      Garantir a manutenção das evidências de execução e
                      implementação das iniciativas de privacidade, no âmbito
                      regional (princípio da responsabilização); e{" "}
                    </li>
                    <li>
                      Coordenar as atividades e consultas com o DPO que dá apoio
                      à região.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Influenciador de Privacidade (“Influenciador”){" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Prestar suporte à área de privacidade, a partir de
                      treinamentos recebidos do Líder de Privacidade Corporativo
                      / DPE;{" "}
                    </li>
                    <li>
                      Facilitar a coleta de evidências sobre a aplicação das
                      regras internas de privacidade e proteção de Dados
                      Pessoais; e{" "}
                    </li>
                    <li>
                      Disseminar a cultura de privacidade e proteção de Dados
                      Pessoais nas respectivas áreas.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Segurança da Informação (“SI”){" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Analisar violações e vazamentos de Dados Pessoais bem como
                      efetuar a coleta de evidências técnicas;{" "}
                    </li>
                    <li>
                      Monitorar e implementar medidas de segurança para garantir
                      o cumprimento da legislação e da regulamentação
                      aplicáveis;{" "}
                    </li>
                    <li>
                      Publicar avisos de privacidade em websites e programas
                      externos;{" "}
                    </li>
                    <li>
                      Revisar e manter atualizada a Documentação Orientadora
                      relativa à Segurança da Informação que estejam na sua
                      competência;{" "}
                    </li>
                    <li>
                      Definir procedimento e <i>templates</i> para formalização
                      de incidentes de Dados Pessoais;{" "}
                    </li>
                    <li>
                      Implementar mecanismos para garantir os direitos dos
                      Titulares de Dados;{" "}
                    </li>
                    <li>
                      Prestar suporte técnico e analisar novas ferramentas e
                      sistemas com foco na exposição de Dados Pessoais; e{" "}
                    </li>
                    <li>
                      Garantir a aplicação das medidas de segurança
                      proporcionais ao risco gerado pelo Tratamento de Dados
                      Pessoais e em linha com a expectativa de proteção do
                      Titular do Dado Pessoal, garantindo a integridade,
                      disponibilidade e confidencialidade destas informações.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Área Jurídica </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Assegurar que os contratos que contemplem a cessão ou o
                      Tratamento de Dados Pessoais contenham cláusulas de
                      privacidade adequadas à legislação e regulamentação
                      aplicáveis;{" "}
                    </li>
                    <li>
                      Prestar apoio jurídico na ocorrência de vazamentos de
                      Dados Pessoais;{" "}
                    </li>
                    <li>
                      Prestar apoio jurídico na interpretação da legislação e
                      regulamentação relativas à proteção de Dados Pessoais;{" "}
                    </li>
                    <li>
                      Apoiar na renegociação de contratos/aditivos com
                      fornecedores e clientes que realizam o Tratamento de Dados
                      Pessoais; e{" "}
                    </li>
                    <li>
                      Apoiar na interface com Autoridades Nacionais de Dados
                      Pessoais.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Todos os Integrantes da DNASec, incluindo os Líderes{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades;{" "}
                    </li>
                    <li>
                      Cumprir a legislação e regulamentação aplicáveis, bem como
                      Documentação Orientadora da <span>DNASec</span> relativos
                      à proteção de Dados Pessoais e aplicação das medidas
                      adequadas de segurança de TI;{" "}
                    </li>
                    <li>
                      Relatar para o Líder de Privacidade Corporativo ou seus
                      representantes regionais a ocorrência de quaisquer
                      incidentes de Dados Pessoais ou segurança de dados, bem
                      como as deficiências identificadas relacionadas ou
                      possíveis riscos de privacidade; e{" "}
                    </li>
                    <li>
                      Participar das atividades de treinamento em proteção de
                      dados conforme orientado.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Auditoria Interna </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Responsabilizar-se pelo uso adequado de Dados Pessoais em
                      suas atividades; e{" "}
                    </li>
                    <li>
                      Incluir avaliação de aderência à Documentação Orientadora
                      que versa sobre proteção de Dados Pessoais nos projetos de
                      auditoria e reportar ao Líder de Privacidade Corporativo e
                      ao CC o resultado dessas avaliações.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Quem é o responsável por essa política?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Como nosso oficial de Proteção de Dados (DPO){" "}
                    <span>George Silverio da Silva</span>, tem a
                    responsabilidade geral pela implementação diária desta
                    política. Você deve entrar em contato com o DPO para obter
                    mais informações sobre esta política, se necessário, através
                    do e-mail:{" "}
                    <a href="mailto: george@securitybox.com.br">
                      george@securitybox.com.br
                    </a>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Política</Accordion.Header>
          <Accordion.Body>
            <div>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Princípios de Proteção de Dados Pessoais{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <p>
                      Esta seção descreve os princípios que devem ser observados
                      na coleta, manuseio, armazenamento, divulgação e
                      Tratamento de “Dados Pessoais” pela <span>DNASec</span>{" "}
                      para atender aos padrões de proteção de dados no âmbito
                      corporativo e estar em conformidade com a legislação e
                      regulamentação aplicáveis nos respectivos países onde
                      tiver operação ou atividade comercial.{" "}
                    </p>
                  </div>
                  <div>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Legalidade, Transparência e Não Discriminação{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> trata os Dados Pessoais de forma
                          justa, transparente e em conformidade com legislação e
                          regulamentação aplicáveis.
                        </div>
                        <div>
                          A <span>DNASec</span> somente trata Dados Pessoais
                          quando o propósito/finalidade do Tratamento se
                          enquadra em uma das hipóteses legais permitidas,
                          abaixo elencadas, sendo certo que os Titulares de
                          Dados devem ser informados sobre a razão e a forma
                          pela qual seus Dados Pessoais estão sendo tratados
                          antes ou durante a coleta:{" "}
                        </div>
                        <ul>
                          <li>
                            Necessidade para a execução de um contrato do qual o
                            Titular dos Dados é parte;{" "}
                          </li>
                          <li>
                            Exigência decorrente de lei ou regulamento ao qual a{" "}
                            <span>DNASec</span> está sujeita;{" "}
                          </li>
                          <li>
                            Interesse legítimo pelo Tratamento, hipótese na qual
                            tal interesse legítimo será comunicado previamente;
                            e{" "}
                          </li>
                          <li>
                            Necessidade de prover ao Titular dos Dados o
                            exercício regular de direito em processo judicial,
                            administrativo ou arbitral.{" "}
                          </li>
                        </ul>
                        <div>
                          Quando o Tratamento de Dados Pessoais não se
                          enquadrarem nas hipóteses acima, a <span>DNASec</span>{" "}
                          deve obter o Consentimento dos Titulares dos Dados
                          para o Tratamento de seus Dados Pessoais, e assegurar
                          que este Consentimento e seja obtido de forma
                          específica, livre, inequívoca informada. A{" "}
                          <span>DNASec</span> deve coletar, armazenar e
                          gerenciar todas as respostas de Consentimento de
                          maneira organizada e acessível, para que a comprovação
                          de Consentimento possa ser fornecida quando
                          necessário.{" "}
                        </div>
                        <div>
                          Da mesma forma, o Titular de Dados deve ter a
                          possibilidade de retirar o seu Consentimento a
                          qualquer momento com a mesma facilidade que foi
                          fornecido.{" "}
                        </div>
                        <div>
                          Em algumas circunstâncias a <span>DNASec</span> também
                          pode ser obrigada a tratar Dados Pessoais Sensíveis,
                          envolvendo, mas não limitado a:{" "}
                        </div>
                        <ul>
                          <li>Dados relacionados à saúde ou à vida sexual </li>
                          <li>
                            Dados genéticos ou biométricos vinculados a uma
                            pessoa física;{" "}
                          </li>
                          <li>Dados sobre orientação sexual; </li>
                          <li>
                            Dados sobre condenações ou ofensas criminais;{" "}
                          </li>
                          <li>
                            Dados que evidenciem a origem racial ou étnica,
                            opiniões políticas, crenças religiosas ou
                            filosóficas; e{" "}
                          </li>
                          <li>
                            Dados referentes à convicção religiosa, opinião
                            política, filiação a sindicato ou a organização de
                            caráter religioso, filosófico ou político.{" "}
                          </li>
                        </ul>
                        <div>
                          O Tratamento de Dados Pessoais Sensíveis é proibido,
                          exceto nos casos específicos descritos abaixo, nos
                          quais deverão ser observados padrões de segurança mais
                          robustos do que os empregados aos demais Dados
                          Pessoais:
                        </div>
                        <ul>
                          <li>
                            Quando for necessário para o cumprimento de
                            obrigação legal ou regulatória;{" "}
                          </li>
                          <li>
                            Quando for necessário para o exercício regular de
                            direitos como, por exemplo, defesa ou proposição de
                            ações judiciais ou administrativas ou arbitrais;{" "}
                          </li>
                          <li>
                            Quando for necessário para o cumprimento de
                            obrigações e o exercício de direitos em matéria de
                            emprego, previdência social e proteção social;{" "}
                          </li>
                          <li>
                            Para proteção à vida ou à incolumidade física do
                            Titular do Dado incluindo dados médicos com fins
                            preventivos, ocupacional;{" "}
                          </li>
                          <li>
                            Para fins de promoção ou manutenção de igualdade de
                            oportunidades entre pessoas de origem racial ou
                            étnica diferente,{" "}
                          </li>
                          <li>
                            Quando o Titular dos Dados tiver dado o seu
                            Consentimento explícito, de acordo com a legislação
                            e regulamentação aplicáveis; e{" "}
                          </li>
                          <li>
                            Quando o Tratamento for relativo a condenações
                            penais e infrações ou a medidas de proteção
                            relacionadas será efetuado sob o controle da
                            autoridade pública ou quando o Tratamento for
                            autorizado pela legislação da União ou de um
                            Estado-Membro que preveja as salvaguardas adequadas
                            para os direitos e liberdades dos Titulares de Dados
                            Pessoais.{" "}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Limitação e Adequação da Finalidade{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          O Tratamento de Dados Pessoais deve ser realizado de
                          maneira compatível com a finalidade original para a
                          qual os Dados Pessoais foram coletados, não podendo
                          ser coletados com um propósito e utilizados para
                          outro. Quaisquer outras finalidades devem ser
                          compatíveis com a razão original para qual os Dados
                          Pessoais foram coletados.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Princípio da Necessidade (Minimização dos Dados){" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> somente pode tratar Dados
                          Pessoais na medida em que seja necessário para atingir
                          um propósito específico, este é o princípio da
                          minimização de dados. O compartilhamento de Dados
                          Pessoais com outra área ou outra empresa deve
                          considerar este princípio, só podendo ser
                          compartilhados quando tenham um amparo legal adequado.
                          Os dados coletados não serão, em hipótese alguma,
                          compartilhados com terceiros para fins comerciais e
                          publicitários.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Exatidão (Qualidade dos Dados){" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> deve adotar medidas razoáveis
                          para assegurar que quaisquer Dados Pessoais em sua
                          posse sejam mantidos precisos, atualizados em relação
                          às finalidades para as quais foram coletados, sendo
                          certo que deve ser possibilitado ao Titular do Dado
                          Pessoal a possibilidade de se requerer a exclusão ou
                          correção de dados imprecisos ou desatualizados.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Retenção e Limitação do Armazenamento de Dados{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> deve ter conhecimento de suas
                          atividades de Tratamento, períodos de retenção
                          estabelecidos e processos de revisão periódica, não
                          podendo manter os Dados Pessoais por prazo superior ao
                          necessário para atender as finalidades pretendidas.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Integridade e Confidencialidade (Livre Acesso, Prevenção
                        e Segurança){" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> deve assegurar que medidas
                          técnicas e administrativas apropriadas sejam aplicadas
                          aos Dados Pessoais para protege-los contra o
                          Tratamento não autorizado ou ilegal, bem como contra a
                          perda acidental, destruição ou danos. O Tratamento de
                          Dados Pessoais também deve garantir a devida
                          confidencialidade. Dentre as medidas técnicas mais
                          comuns, podem ser descritas:{" "}
                        </div>
                        <ul>
                          <li>
                            <span>Anonimização</span> significa que os Dados
                            Pessoais são tornados anônimos de tal forma que os
                            dados não mais se referem a uma pessoa direta ou
                            indiretamente identificável. O anonimato tem que ser
                            irreversível.{" "}
                          </li>
                          <li>
                            <span>Pseudonimização</span> é um processo pelo qual
                            os Dados Pessoais não mais se relacionam diretamente
                            com uma pessoa identificável (por exemplo,
                            mencionando seu nome), mas não é anônimo, porque
                            ainda é possível, com informações adicionais, que
                            são mantidas separadamente, identificar uma pessoa.{" "}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Responsabilização e Prestação de Contas{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> é responsável e deve demonstrar
                          o cumprimento desta Política, assegurando a
                          implementação de diversas medidas que incluem, mas não
                          se limitam a:
                        </div>
                        <ul>
                          <li>
                            Garantia de que os Titulares dos Dados Pessoais
                            possam exercer os seus direitos conforme descritos
                            na Seção 5.5 deste Documento;{" "}
                          </li>
                          <li>
                            Registro de Dados Pessoais, incluindo:{" "}
                            <ul>
                              <li>
                                Registros de atividades de Tratamento de Dados
                                Pessoais, com a descrição dos
                                propósitos/finalidades desse Tratamento, os
                                destinatários do compartilhamento dos Dados
                                Pessoais e os prazos pelos quais a{" "}
                                <span>DNASec</span> deve retê-los; e{" "}
                              </li>
                              <li>
                                Registro de incidentes de Dados Pessoais e
                                violações de Dados Pessoais;{" "}
                              </li>
                            </ul>
                          </li>
                          <li>
                            Garantia de que os Terceiros que sejam Operadores de
                            Dados Pessoais também estejam agindo de acordo com
                            esta Política e com a legislação e regulamentação
                            aplicáveis;{" "}
                          </li>
                          <li>
                            Garantia de que a <span>DNASec</span>, quando
                            requerido, registre junto à Autoridade Supervisora
                            aplicável um Encarregado de Dados ou DPO; e{" "}
                          </li>
                          <li>
                            Garantia de que a <span>DNASec</span> esteja
                            cumprindo todas as exigências e solicitações de
                            qualquer Autoridade de Supervisão à qual esteja
                            sujeita.{" "}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Padrões de Segurança </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Importância da Proteção de Dados Pessoais{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A <span>DNASec</span> está comprometida com a
                          implementação dos padrões de Segurança da Informação e
                          com a proteção de Dados Pessoais com vistas a garantir
                          o direito fundamental do indivíduo à autodeterminação
                          da informação.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Garantir a Segurança dos Dados Pessoais{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          A confidencialidade, integridade e disponibilidade,
                          bem como autenticidade, responsabilidade e não-repúdio
                          são objetivos a serem perseguidos para a segurança dos
                          Dados Pessoais.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Obrigação do Sigilo de Dados Pessoais{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          Todos os Integrantes com acesso a Dados Pessoais estão
                          obrigados aos deveres de confidencialidade dos Dados
                          Pessoais mediante a anuência no Código de Conduta e
                          Termos de Uso da <span>DNASec</span>, quando do
                          ingresso na Empresa e periodicamente quando
                          necessário.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Privacidade de Dados Pessoais por Concepção e por Padrão{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          Ao implementar novos processos, procedimentos ou
                          sistemas que envolvam o Tratamento de Dados Pessoais,
                          a <span>DNASec</span> deve adotar medidas para
                          garantir que as regras de Privacidade e Proteção de
                          Dados sejam adotadas desde a fase de concepção até o
                          lançamento/implantação destes projetos.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Relação Controlador-Operador de Dados Pessoais{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Cada Parceiro da <span>DNASec</span> é o Controlador dos
                    Dados Pessoais em sua respectiva região ou empresa, sendo
                    necessária a nomeação de um responsável por garantir que os
                    Dados Pessoais estejam sendo tratados de forma correta e de
                    acordo com a legislação e regulamentação aplicáveis naquela
                    região. Em determinadas circunstâncias, um Parceiro
                    Controlador da
                    <span>DNASec</span> pode atuar como Operador de outra.
                    Nestes casos, a Operadora é obrigada a seguir a orientação
                    de quem está atuando como Controladora.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Política de Transferência Internacional de Dados Pessoais{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Quando os Dados Pessoais forem tratados em países diferentes
                    de onde foram coletados, a legislação e regulamentação
                    aplicáveis à transferência internacional de dados de cada
                    país devem ser observadas. A <span>DNASec</span> deve
                    garantir a existência e atualização de contratos de
                    transferência internacional de Dados Pessoais.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Direitos dos Titulares de Dados Pessoais{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    A <span>DNASec</span> está comprometida com os direitos dos
                    Titulares de Dados Pessoais, os quais incluem:{" "}
                  </div>
                  <ul>
                    <li>
                      A informação, no momento em que os Dados Pessoais são
                      fornecidos, sobre como seus Dados Pessoais serão tratados;{" "}
                    </li>
                    <li>
                      A informação sobre o Tratamento de seus Dados Pessoais e o
                      acesso aos Dados Pessoais que a <span>DNASec</span>{" "}
                      detenha sobre eles;{" "}
                    </li>
                    <li>
                      A correção de seus Dados Pessoais se estiverem imprecisos,
                      incorretos ou incompletos;{" "}
                    </li>
                    <li>
                      A exclusão, bloqueio e/ou anonimização de seus Dados
                      Pessoais em determinadas circunstâncias (“direito de ser
                      esquecido”). Isso pode incluir, mas não se limita a,
                      circunstâncias em que não é mais necessário que a{" "}
                      <span>DNASec</span>
                      retenha seus Dados Pessoais para os propósitos para os
                      quais foram coletados;{" "}
                    </li>
                    <li>
                      A restrição do Tratamento de seus Dados Pessoais em
                      determinadas circunstâncias;{" "}
                    </li>
                    <li>
                      Opor-se ao Tratamento, se o Tratamento for baseado em
                      legítimo interesse{" "}
                    </li>
                    <li>
                      A retirar o Consentimento a qualquer momento, se o
                      Tratamento dos Dados Pessoais se basear no Consentimento
                      do indivíduo para um propósito específico;{" "}
                    </li>
                    <li>
                      A portabilidade dos Dados Pessoais a outro fornecedor de
                      serviço ou produto, mediante requisição expressa em
                      determinadas circunstâncias;{" "}
                    </li>
                    <li>
                      A revisão das decisões tomadas unicamente com base em
                      Tratamento automatizado de Dados Pessoais; e{" "}
                    </li>
                    <li>
                      A apresentação de queixa à <span>DNASec</span> ou à
                      Autoridade Nacional de Proteção de Dados aplicável, se o
                      Titular dos Dados Pessoais tiver motivos para supor que
                      qualquer um de seus direitos de proteção de Dados Pessoais
                      tenha sido violado.{" "}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Prestadores de Serviços Terceirizados{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Os prestadores de serviços terceirizados que tratem Dados
                    Pessoais sob as instruções da <span>DNASec</span> estão
                    sujeitos às obrigações impostas aos Operadores de acordo com
                    a legislação e regulamentação de proteção de Dados Pessoais
                    aplicáveis. A <span>DNASec</span> deve assegurar que no
                    contrato de prestação de serviço sejam contempladas as
                    cláusulas de privacidade que exijam que o Operador de Dados
                    terceirizado implemente medidas de segurança, bem como
                    controles técnicos e administrativos apropriados para
                    garantir a confidencialidade e segurança dos Dados Pessoais
                    e especifiquem que o Operador está autorizado a tratar Dados
                    Pessoais apenas quando seja formalmente solicitado pela
                    <span>DNASec</span>.{" "}
                  </div>
                  <div>
                    Nos casos em que o prestador de serviços estiver localizado
                    fora do país em que o Dado Pessoal foi coletado, as
                    cláusulas contratuais padrão devem ser incluídas no contrato
                    de proteção de Dados Pessoais como um Anexo para garantir
                    que as devidas salvaguardas exigidas pela legislação e
                    regulamentação aplicáveis de proteção de Dados Pessoais
                    sejam implementadas.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Gerenciamento de Violação de Dados{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Todos os incidentes e potenciais violações de dados devem
                    ser reportadas ao Líder de Privacidade Corporativo e/ou DPE
                    de cada região. Todos os Integrantes devem estar cientes de
                    sua responsabilidade pessoal de encaminhar e escalonar
                    possíveis problemas, bem como de denunciar violações ou
                    suspeitas de violações de Dados Pessoais assim que as
                    identificarem. No momento em que um incidente ou violação
                    real for descoberto, é essencial que os incidentes sejam
                    informados e formalizados de forma tempestiva.{" "}
                  </div>
                  <div>
                    Violações de Dados incluem, mas não se limitam a, qualquer
                    perda, exclusão, roubo ou acesso não autorizado de Dados
                    Pessoais controlados ou tratados pela <span>DNASec</span>.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Auditorias de Proteção de Dados{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    A <span>DNASec</span> deve garantir que existam revisões
                    periódicas a fim de confirmar que as iniciativas de
                    Privacidade, seu sistema, medidas, processos, precauções e
                    outras atividades incluindo o gerenciamento de proteção de
                    Dados Pessoais são efetivamente implementados e mantidos e
                    estão em conformidade com a legislação e regulamentação
                    aplicáveis.{" "}
                  </div>
                  <div>
                    Adicionalmente e conforme previsto na Diretriz de Auditoria
                    Interna, o tema deve ser avaliado com a devida periodicidade
                    e de acordo com os riscos existentes. Caso os riscos sejam
                    relevantes a Auditoria Interna deverá incluir revisão
                    independente específica no plano anual de auditoria interna.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Disposições Gerais </Accordion.Header>
          <Accordion.Body>
            <div>
              Os Integrantes são responsáveis por conhecer e compreender todos
              os Documentos Orientadores que lhes forem aplicáveis. De forma
              similar, os Líderes são responsáveis por garantir que todos os
              Integrantes de sua equipe compreendam e sigam os Documentos
              Orientadores aplicáveis à <span>DNASec</span>.{" "}
            </div>
            <div>
              Os Integrantes que tiverem perguntas ou dúvidas a respeito desta
              Política, incluindo seu escopo, termos ou obrigações, devem
              procurar seus respectivos Líderes e, se necessário a área de
              Gestão de Riscos / Conformidade da <span>DNASec</span>.{" "}
            </div>
            <div>
              Violações de qualquer Documentação Orientadora da{" "}
              <span>DNASec</span> podem resultar em consequências graves à{" "}
              <span>DNASec</span> e aos Integrantes envolvidos. Portanto, a
              falha em cumprir esta Política ou relatar o conhecimento de
              violação desta Política poderá resultar em ação disciplinar para
              qualquer Integrante envolvido.{" "}
            </div>
            <div>
              Caso qualquer Integrante e/ou Terceiro tenha conhecimento de uma
              potencial conduta ilegal ou antiética, incluindo potenciais
              violações às Leis Anticorrupção aplicáveis e/ou Documentações
              Orientadoras da <span>DNASec</span>, incluindo este Documento,
              devem imediatamente reportar a potencial violação ao Canal Linha
              de Ética ou a área de <i>Compliance</i> da <span>DNASec</span>.
              Todos os Líderes devem continuamente encorajar seus liderados a
              reportar violações ao Canal Linha de Ética. Nenhuma regra prevista
              nas Documentações Orientadoras da <span>DNASec</span>, incluindo
              esse Documento, proibirá que Integrantes ou Terceiros possam
              reportar preocupações ou atividades ilegais para as autoridades
              reguladoras correspondentes.{" "}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="px-5 pb-1">
        <div>
          <p>
            Conselho Técnico e Administrativo <span>DNASec</span>
            {"    "}|{"    "}23/05/2022{" "}
          </p>
          <p> </p>
        </div>
        <div></div>
      </div>
    </>
  );
};
