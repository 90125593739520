import React, { FC } from 'react'

import { classNames } from '../../../util/class-names'

interface CardProps {
  className?: string
  elevation?: number
}

export const Card: FC<CardProps> = ({className, elevation, ...props}) => {
  const classes = classNames(
    'card',
    className
  )
  return (
    <div className={classes} {...props} />
  )
}