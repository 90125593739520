import React, { FC, useState, useEffect, useRef } from "react";

import { useOpportunities } from "../../shared/data";
import { useAuth } from "../../shared/auth";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import { FiFilter } from "react-icons/fi";
import FilterSearch from "pages/components/FilterSearch";
import { FilterDate } from "../../pages/components/FilterDate";
import type { IFilterDate } from "pages/components/FilterDate";
import { format } from "date-fns";
import moment from "moment";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";

import { MainLayout } from "pages/components/MainLayout";
import styles from "./OpportunityView.module.scss";

export const OpportunityList: FC = () => {
  const { user } = useAuth();
  const opportunities = useOpportunities(user.id);

  const [openFilters, setOpenFilters] = useState({
    tradingName: false,
    contact: false,
    dthora: false,
  });

  const toggleFilter = (key: keyof typeof openFilters) => {
    setOpenFilters((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const dthoraRef = useRef<HTMLDivElement>(null);
  const tradingNameRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const filterRefs = [
    { ref: dthoraRef, key: "dthora" },
    { ref: contactRef, key: "contact" },
    { ref: tradingNameRef, key: "tradingName" },
  ];

  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [valueFilterTrading, setvalueFilterTrading] = useState("");
  const [dataValueFilter, setDataValueFilter] = useState();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [valueFilterContact, setvalueFilterContact] = useState("");
  const [dataValueFilterContact, setDataValueFilterContact] = useState();

  const filterData = (value) => {
    setDataValueFilter(value);
  };

  const filterTrading = (value) => {
    setvalueFilterTrading(value);
  };

  const filterDataRegister = (value: IFilterDate) => {
    setStartDate(moment(value.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(value.endDate).format("YYYY-MM-DD"));
  };

  const filterDataClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const filterContact = (value) => {
    setvalueFilterContact(value);
  };

  const filterDataContact = (value) => {
    setDataValueFilterContact(value);
  };

  useEffect(() => {
    let filtered = opportunities;

    if (valueFilterTrading) {
      filtered = filtered.filter((item) =>
        item.tradingName
          .normalize("NFKD")
          .replace(/^\s+|\p{M}/gu, "")
          .toLowerCase()
          .includes(
            valueFilterTrading.toLowerCase().replace(/^\s+|\p{M}/gu, "")
          )
      );
    }

    if (valueFilterContact) {
      filtered = filtered.filter((item) =>
        item.leadRepresentative
          .normalize("NFKD")
          .replace(/^\s+|\p{M}/gu, "")
          .toLowerCase()
          .includes(
            valueFilterContact.toLowerCase().replace(/^\s+|\p{M}/gu, "")
          )
      );
    }

    if (startDate && endDate) {
      filtered = filtered.filter((item) => {
        const createdDate = new Date(
          moment(item.created_at).format("YYYY-MM-DD")
        );
        const start = new Date(startDate);
        const end = new Date(endDate);
        return createdDate >= start && createdDate <= end;
      });
    }
    if (filtered) {
      setFilteredOpportunities(filtered);
    }
  }, [
    opportunities,
    valueFilterTrading,
    valueFilterContact,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      filterRefs.forEach(({ ref, key }) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpenFilters((prev) => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRefs]);

  return (
    <MainLayout title="Listagem de Oportunidades">
      <Table hover>
        <TableHead className={styles.filtro}>
          <TableCell>Detalhes</TableCell>
          <TableCell>CNPJ</TableCell>
          <TableCell>
            Empresa
            <FiFilter
              type="button"
              onClick={() => toggleFilter("tradingName")}
              style={{ color: valueFilterTrading ? "#000000" : "" }}
            />
            {openFilters.tradingName ? (
              <div ref={tradingNameRef}>
                <FilterSearch
                  id="tradingName"
                  dataValueFilter={dataValueFilter}
                  filterValue={filterTrading}
                  dataFilter={filterData}
                />
              </div>
            ) : null}
          </TableCell>
          <TableCell>Telefone da Empresa</TableCell>
          <TableCell>
            Contato
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("contact");
              }}
              style={{ color: valueFilterContact ? "#000000" : "" }}
            />
            {openFilters.contact ? (
              <div ref={contactRef}>
                <FilterSearch
                  id="contact"
                  dataValueFilter={dataValueFilterContact}
                  filterValue={filterContact}
                  dataFilter={filterDataContact}
                />
              </div>
            ) : null}
          </TableCell>
          <TableCell>E-mail do Contato</TableCell>
          <TableCell>Telefone do Contato</TableCell>
          <TableCell>
            Data/Hora Cadastro
            <FiFilter
              type="button"
              onClick={() => {
                toggleFilter("dthora");
              }}
              style={{ color: endDate ? "#000000" : "" }}
            />
            {openFilters.dthora ? (
              <div ref={dthoraRef}>
                <FilterDate
                  id="dthora"
                  filterValue={filterDataRegister}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  filterValueClean={filterDataClean}
                />
              </div>
            ) : null}
          </TableCell>
        </TableHead>

        <TableBody>
          {opportunities !== undefined && opportunities.length > 0 ? (
            filteredOpportunities.map((opportunities) => (
              <TableRow key={opportunities.id}>
                <TableCell>
                  <IconButton to={`/opportunities/view/${opportunities.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>

                <TableCell>{opportunities.cnpj}</TableCell>
                <TableCell>{opportunities.tradingName}</TableCell>
                <TableCell>{opportunities.phone}</TableCell>
                <TableCell>{opportunities.leadRepresentative}</TableCell>
                <TableCell>{opportunities.leadRepMail}</TableCell>
                <TableCell>{opportunities.leadRepPhone}</TableCell>
                <TableCell>
                  {format(
                    new Date(opportunities.created_at),
                    "dd/MMM/yyyy - HH:mm:ss"
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableBody>
              <p>Os Leads aprovados aparecerão aqui.</p>
            </TableBody>
          )}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
