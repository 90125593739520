import { getToken, setRefreshToken, setToken } from './auth'
import { getEndpoint } from '../util/request'
interface HttpRequestParams {
  method: 'GET' | 'PATCH' | 'POST' | 'PUT'
  url: string
}

export interface HttpResponse<T> {
  body?: T
  status: number
}

export class HttpRequest<T> {

  private request: XMLHttpRequest
  constructor(params: HttpRequestParams) {

    this.request = new XMLHttpRequest()
    this.request.open(params.method, `${getEndpoint()}${params.url}`)
    const token = getToken()
    if (token) {
      this.request.setRequestHeader('Authorization', `Bearer ${token}`)
    }
    this.request.setRequestHeader('Content-Type', 'application/json')
    this.request.setRequestHeader('Accept', 'application/json')
  }

  abort() {
    this.request.abort()
  }

  send(body?: any): Promise<HttpResponse<T>> {
    return new Promise((resolve, reject) => {
      this.request.addEventListener('load', () => {
        try {
          const body = JSON.parse(this.request.responseText)
          const status = this.request.status
          if (this.request.status === 200) {
            resolve({body, status})
          } else {
            if (status === 401){
              if (body.message === 'Token invalid'){
                setToken(null);
                setRefreshToken(null);
      
                window.location.href = '#login';
                window.location.reload();
              }
            }
            reject({body, status})
          }
        } catch (e) {

          const status = this.request.status
          if (this.request.status === 200) {
            resolve({status})
          } else {
            reject({status})
          }
        }
      })
      this.request.addEventListener('error', () => {
        const status = this.request.status

        reject({status})
      })
      this.request.send(body)
    })
  }

}