export enum BusinessBranches {
  Law = 1,
  Dpo = 2,
  Consulting = 3,
  SecurityIntegrator = 4,
  Ti = 5
}

export const EBusinessBranches = new Map<BusinessBranches, string>([
  [BusinessBranches.Law, 'Advocacia'],
  [BusinessBranches.Dpo, 'Proteção de Dados'],
  [BusinessBranches.Consulting, 'Consultoria'],
  [BusinessBranches.SecurityIntegrator, 'Integração de Segurança'],
  [BusinessBranches.Ti, 'Tecnologia de Informação']
])