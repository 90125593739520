import { useEffect, useState } from 'react'
import { data } from '../data-service'

export function useOpportunities(user_id: string) {
  const [ opportunities, setOpportunities ] = useState<Opportunity[]>()
  useEffect(() => {
    data.get<Opportunity[]>(`/opportunities/${ user_id }`)
      .then(response => response.body)
      .then(opportunities => setOpportunities(opportunities!))
  }, [user_id])
  return opportunities
}

export function useOpportunity(key: OpportunityKey) {
  const [ opportunity, setOpportunity ] = useState<Opportunity>()
  useEffect(() => {
    if ('id' in key) {
      data.get<Opportunity>(`/viewOpportunities/${key.id}`)
        .then(response => response.body)
        .then(opportunity => setOpportunity(opportunity!))
    }
  }, [key])
  return opportunity
}