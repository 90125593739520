import React, { FC } from "react";
import { MainLayout } from "pages/components/MainLayout";
import Footer from "../../../admin-console/src/shared/layout/components/Footer";
import { ContactForm } from "pages/contacts/ContactForm";

export const ContactPage: FC = () => {
  return (
    <>
      <MainLayout button />
      <ContactForm />
      <Footer />
    </>
  );
};
