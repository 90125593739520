import React, { FC, Fragment } from "react";

import { ELeadStatus, EPaymentTypes } from "../../shared/data/enums";
import { useOrder } from "../../shared/data/hooks/orders";
import { Form } from "../../shared/form/Form";
import { FormGroup } from "../../shared/form/FormGroup";
import { FormInput } from "../../shared/form/FormInput";
import { Card, CardBody } from "../../shared/layout/bootstrap/card";
import { Col, Row } from "../../shared/layout/bootstrap/grid";
import { formatCurrency, formatDate, formatPercent } from "../../shared/util/format";

import { MainLayout } from "pages/components/MainLayout";

export const OrderView: FC<ViewComponentProps> = ({ match: { params } }) => {
  const order = useOrder(params.id);
  const currentQuote = order?.opportunity?.quotes?.find((quote) => {
    return (
      order.opportunity.quotes.findIndex(
        (current) => current.createdAt > quote.createdAt
      ) < 0
    );
  });
  return (
    <MainLayout title="Visualizar Assinatura" arrowBack>
      {order && (
        <Card>
          <CardBody>
            <Form readOnly>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="E-mail para Fatura">
                    <FormInput value={order.invoiceEmail} />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Pagamento">
                    <FormInput value={EPaymentTypes.get(order.paymentType)} />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Dia Vencimento">
                    <FormInput value={`${order.monthlyPaymentExpirationDay}`} />
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <h6>Contato Financeiro</h6>
                </div>
                <div className="col-md-7">
                  <FormGroup label="E-mail">
                    <FormInput value={order.financialContactEmail} />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Telefone">
                    <FormInput
                      value={order.financialContactPhone}
                      mask="phone"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <h6>Informações do DPO</h6>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Nome">
                    <FormInput value={order.dpoName} />
                  </FormGroup>
                </div>
                <div className="col-md-7">
                  <FormGroup label="E-mail">
                    <FormInput value={order.dpoEmail} />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Telefone Fixo">
                    <FormInput value={order.dpoLandLinePhone} mask="phone" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Telefone Móvel">
                    <FormInput value={order.dpoMobilePhone} mask="phone" />
                  </FormGroup>
                </div>
              </div>
            </Form>
            <h6 className="text-primary mt-3 mb-2">OPORTUNIDADE</h6>
            <Form readOnly value={order.opportunity.lead}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Razão social">
                    <FormInput name="client.companyName" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Nome fantasia">
                    <FormInput name="client.tradingName" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="CNPJ">
                    <FormInput name="client.cnpj" mask="cnpj" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Quantidade de Titulares">
                    <FormInput name="client.numberHolders" />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Ramo de Negócios">
                    <FormInput name="client.lineOfBusiness" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Link do site">
                    <FormInput name="client.webSiteUrl" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Telefone">
                    <FormInput name="client.phone" mask="phone" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Expiração">
                    <FormInput
                      value={formatDate(order.opportunity.expirationDate)}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="Status">
                    <FormInput
                      value={ELeadStatus.get(order.opportunity.status)}
                    />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <h6 className="text-primary mt-3 mb-2">ENDEREÇO</h6>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Rua">
                    <FormInput name="client.street" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Número">
                    <FormInput name="client.number" />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup label="Complemento">
                    <FormInput name="client.complement" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Bairro">
                    <FormInput name="client.neighborhood" />
                  </FormGroup>
                </div>
                <div className="col-md-5">
                  <FormGroup label="Cidade">
                    <FormInput name="client.city" />
                  </FormGroup>
                </div>
                <div className="col-md-2">
                  <FormGroup label="UF">
                    <FormInput name="client.state" />
                  </FormGroup>
                </div>
              </div>
              {currentQuote && (
                <Fragment>
                  <h5 className="text-primary mt-3 mb-2">
                    INFORMAÇÕES DA QUOTE ATUAL
                  </h5>
                  <Row>
                    <Col md={3}>
                      <FormGroup label="Fechamento do Pedido">
                        <FormInput value={formatDate(currentQuote.createdAt)} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup label="Plano">
                        <FormInput value={currentQuote.plan.name} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup label="Valor">
                        <FormInput value={formatCurrency(currentQuote.value)} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup label="Valor por Titular">
                        <FormInput
                          value={formatCurrency(
                            currentQuote.plan.valuePerHolder,
                            5
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup label="Código">
                        <FormInput value={currentQuote.code} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup label="Responsável">
                        <FormInput value={order.opportunity.manager.name} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup label="Desconto">
                        <FormInput
                          value={formatPercent(currentQuote.discount)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup label="Valor Final">
                        <FormInput
                          value={formatCurrency(currentQuote.finalValue)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Form>
          </CardBody>
        </Card>
      )}
    </MainLayout>
  );
};
