import React, { Children, FC, ReactElement } from 'react'

import { TableCellProps } from './TableCell'

export interface TableRowProps {
  className?: string
  children: ReactElement<TableCellProps>[]
  header?: boolean
}

export const TableRow: FC<TableRowProps> = ({children, className, header}) => (
  <tr className={className}>
    {Children.map(children, child => React.cloneElement(child, {header}))}
  </tr>
)
