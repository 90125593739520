import React, { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import { classNames } from '../../../util/class-names'

export interface ButtonProps {
  block?: boolean
  className?: string
  disabled?: boolean
  onClick?: () => void
  outline?: boolean
  to?: string
  type?: 'button' | 'reset' | 'submit'
  variant?: 'primary' | 'secondary' | 'success' | 'warning'
}

export const Button: FC<ButtonProps> = ({block, className, onClick, outline, variant, to, type, ...props}) => {
  type = type || 'button'
  const classes = classNames(
    outline ? `btn-outline-${variant}` : `btn-${variant}`,
    block && 'btn-block',
  )
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      event.preventDefault()
      event.stopPropagation()
      onClick()
    }
  }
  if (to) {
    return (
      <Link className={classNames('btn', className, classes)} to={to} {...props} />
    )
  }
  return (
    <button type={type} className={classNames('btn', className, classes)} onClick={handleClick} {...props} />
  )
}