export enum ResaleCategory {
  Silver = 1,
  Gold = 2,
  Platinum = 3,
  Platinumm = 3,
}

export const EResaleCategory = new Map<ResaleCategory, string>([
  [ResaleCategory.Silver, 'Silver'],
  [ResaleCategory.Platinum, 'Platinum'],
  [ResaleCategory.Gold, 'Gold'],
  [ResaleCategory.Platinumm, 'Platinumm']
])