export enum UserType {
  AccountManager = 'accountManager',
  Administrator = 'administrator',
  ChannelManager = 'channelManager',
  CommercialManager = 'commercialManager',
  Contact = 'contact',
  Resale = 'resale'
}

export const EUserType = new Map<UserType, string>([
  [UserType.AccountManager, 'Gerente'],
  [UserType.Administrator, 'Administrador'],
  [UserType.ChannelManager, 'Gerente de Canais'],
  [UserType.CommercialManager, 'Gerente Comercial'],
  [UserType.Contact, 'Contato'],
  [UserType.Resale, 'Revenda']
])