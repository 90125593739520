export interface Izipcode {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  localidade: string;
  logradouro: string;
  uf: string;
}

export default async function findZipCode(zipCode) {
  const result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
    .then((res) => res.json())
    .then((data: Izipcode) => {
      return data;
    })
    .catch((err) => console.log(err));
  return result;
}
