import React, {
  Dispatch,
  FC,
  createContext,
  useState,
  SetStateAction,
  useEffect,
  useContext,
} from "react";

import { FormDataValue } from "./FormContext";

type UseState<T> = [T, Dispatch<SetStateAction<T>>];

interface FormValueContextProviderProps {
  onChange?: (_: FormDataValue) => void;
  value?: FormDataValue;
}

const Context = createContext<UseState<FormDataValue>>(null);

export function useFormValueContext() {
  return useContext(Context);
}

export const FormValueContextProvider: FC<FormValueContextProviderProps> = ({
  children,
  onChange,
  value,
}) => {
  const [current, setCurrent] = useState<FormDataValue>(value) || [];
  useEffect(() => {
    onChange && onChange(current);
  }, [current, onChange]);
  return (
    <Context.Provider value={[current, setCurrent]}>
      {children}
    </Context.Provider>
  );
};
