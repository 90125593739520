import { useEffect, useState } from 'react'

import { data } from '../data-service'

export function useContacts() {
  const [contacts, setContacts] = useState<Contact[]>([])
  useEffect(() => {
    data.get<Contact[]>('/api/contacts?page=1')
      .then(response => response.body)
      .then(body => setContacts(body!))
  }, [])
  return contacts
}

export function useContact(id: string) {
  const [contact, setContact] = useState<Contact>()
  useEffect(() => {
    data.get<Contact>(`/api/contacts/${id}`)
      .then(response => response.body)
      .then(contact => setContact(contact!))
  }, [id])
  return contact
}