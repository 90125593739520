import React, { FC } from "react";

import { useClients } from "../../shared/data/hooks/clients";
import { IconButton } from "../../shared/layout/bootstrap/button/IconButton";
import { IconEyeFill } from "../../shared/layout/bootstrap/icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../shared/layout/bootstrap/table";

import { MainLayout } from "pages/components/MainLayout";

export const ClientList: FC = () => {
  const clients = useClients();
  return (
    <MainLayout title="Listagem de Clientes">
      <Table hover>
        <TableHead>
          <TableRow>
            <TableCell>Nome Fantasia</TableCell>
            <TableCell>CNPJ</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Cidade</TableCell>
            <TableCell>UF</TableCell>
            <TableCell>Nº Titulares</TableCell>
            <TableCell>Ramo de Negócios</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {clients &&
            clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.tradingName}</TableCell>
                <TableCell>{client.cnpj}</TableCell>
                <TableCell>{client.phone}</TableCell>
                <TableCell>{client.city}</TableCell>
                <TableCell>{client.state}</TableCell>
                <TableCell>{client.numberHolders}</TableCell>
                <TableCell>{client.lineOfBusiness}</TableCell>
                <TableCell>
                  <IconButton to={`/clients/view/${client.id}`}>
                    <IconEyeFill />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </MainLayout>
  );
};
