import React, { FC } from 'react'

import { classNames } from '../../../util/class-names'

import styles from './Table.module.scss'

interface TableProps {
  className?: string
  hover?: boolean
}

export const Table: FC<TableProps> = ({className, children, hover}) => {
  const classes = classNames(
    'table',
    hover && 'table-hover',
    className,
    styles.table
  )
  return (
    <table className={classes}>
      {children}
    </table>
  )
}
