export enum QuoteStatus {
  Active = 1,
  Expired = 2,
  Closed = 3,
  Canceled = 4
}

export const EQuoteStatus = new Map<QuoteStatus, string>([
  [QuoteStatus.Active, 'Ativa'],
  [QuoteStatus.Expired, 'Expirada'],
  [QuoteStatus.Closed, 'Fechada'],
  [QuoteStatus.Canceled, 'Cancelada']
])