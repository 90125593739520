import React, { FC, ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "../bootstrap/button/IconButton";
import { IconArrowLeft } from "../bootstrap/icon";
import { Header } from "../components/Header";
import { classNames } from "../../util/class-names";
//import Button from '../components/Button';

import styles from "./PageLayout.module.scss";

interface PageLayoutProps {
  action?: ReactNode;
  arrowBack?: boolean;
  className?: string;
  header?: FC;
  button?: boolean;
  title?: string;
  titleIconButton?: FC;
}

export const PageLayout: FC<PageLayoutProps> = ({
  action = null,
  arrowBack,
  className,
  children,
  header: HeaderContent,
  button,
  title,
  titleIconButton: TitleIconButton,
}) => {
  const history = useHistory();
  const handleBack = () => history.goBack();
  return (
    <div className={classNames(styles.root, className)}>
      <Header className={styles.header}>
        {HeaderContent && <HeaderContent />}
        {/* {button && <Button label='Portal de Canais' />} */}
      </Header>
      <div className={styles.content}>
        <div className={styles.newContainer}>
          {title && (
            <div className={styles.pageHeader}>
              <IconButton
                className={classNames(styles.back, arrowBack && styles.visible)}
                onClick={handleBack}
              >
                <IconArrowLeft />
              </IconButton>
              <h1 className={styles.title}>{title}</h1>
              {TitleIconButton && <TitleIconButton />}
              <div className="ml-auto">{action}</div>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
