import React, { FC, createRef, useEffect } from "react";
import { Chart } from "chart.js";

import { Card, CardBody } from "../../../shared/layout/bootstrap/card";
import classes from "./Resale.module.scss";

export const Resale: FC = () => {
  const chart1 = createRef<HTMLCanvasElement>();
  const chart2 = createRef<HTMLCanvasElement>();
  const chart3 = createRef<HTMLCanvasElement>();
  const chart4 = createRef<HTMLCanvasElement>();
  const chart5 = createRef<HTMLCanvasElement>();
  const chart6 = createRef<HTMLCanvasElement>();
  useEffect(() => {
    if (chart1.current) {
      new Chart(chart1.current, {
        type: "line",
        data: {
          labels: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          datasets: [
            {
              borderColor: "lightblue",
              fill: false,
              label: "Assinaturas",
              data: Array.from({ length: 12 }, () =>
                Math.floor(Math.random() * 100)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
    if (chart2.current) {
      new Chart(chart2.current, {
        type: "line",
        data: {
          labels: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          datasets: [
            {
              borderColor: "red",
              fill: false,
              label: "Leads",
              data: Array.from(
                { length: 12 },
                () => 50 + Math.floor(Math.random() * 100)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
    if (chart3.current) {
      new Chart(chart3.current, {
        type: "line",
        data: {
          labels: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          datasets: [
            {
              borderColor: "darkblue",
              fill: false,
              label: "Vendas",
              data: Array.from(
                { length: 12 },
                () => 5 + Math.floor(Math.random() * 100)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
    if (chart4.current) {
      new Chart(chart4.current, {
        type: "pie",
        data: {
          labels: ["Pagas", "Pendentes", "Liberadas"],
          datasets: [
            {
              backgroundColor: [
                "rgb(253, 101, 133)",
                "rgb(61, 163, 132)",
                "rgb(50, 206, 205)",
              ],
              fill: false,
              label: "Comissões",
              data: Array.from({ length: 3 }, () =>
                Math.floor(Math.random() * 100)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
    if (chart5.current) {
      new Chart(chart5.current, {
        type: "horizontalBar",
        data: {
          labels: ["Ganhas", "Perdidas"],
          datasets: [
            {
              backgroundColor: ["rgb(61, 163, 132)", "#FF0000"],
              label: "Oportunidades",
              data: Array.from({ length: 2 }, () =>
                Math.floor(Math.random() * 1000)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
    if (chart6.current) {
      new Chart(chart6.current, {
        type: "bar",
        data: {
          labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          datasets: [
            {
              backgroundColor: [
                "rgb(253, 101, 133)",
                "rgb(61, 163, 132)",
                "rgb(50, 206, 205)",
                "rgb(205, 163, 132)",
                "rgb(50, 206, 10)",
              ],
              fill: false,
              label: "Pedidos",
              data: Array.from({ length: 5 }, () =>
                Math.floor(Math.random() * 100)
              ),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });
    }
  }, [chart1, chart2, chart3, chart4, chart5, chart6]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <h6>Assinaturas ativas por mês</h6>
              <div className={classes.chart1}>
                <canvas ref={chart1} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-6 pt-4">
          <Card>
            <CardBody>
              <h6>Leads por mês</h6>
              <div className={classes.chart1}>
                <canvas ref={chart2} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-6 pt-4">
          <Card>
            <CardBody>
              <h6>Vendas por contato por mês</h6>
              <div className={classes.chart1}>
                <canvas ref={chart3} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4 pt-4">
          <Card>
            <CardBody>
              <h6>Comissões</h6>
              <div className={classes.chart1}>
                <canvas ref={chart4} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4 pt-4">
          <Card>
            <CardBody>
              <h6>Oportunidades</h6>
              <div className={classes.chart1}>
                <canvas ref={chart5} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4 pt-4">
          <Card>
            <CardBody>
              <h6>Pedidos Fechados</h6>
              <div className={classes.chart1}>
                <canvas ref={chart6} />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
