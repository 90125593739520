import { HttpRequest, HttpResponse } from './http-request'

function get<T>(url: string, params?: any): Promise<HttpResponse<T>> {
  const method = 'GET'
  const request = new HttpRequest<T>({method, url})
  return request.send()
}

function patch<T>(url: string, body?: any): Promise<HttpResponse<T>> {
  const method = 'PATCH'
  const request = new HttpRequest<T>({method, url})
  return request.send(JSON.stringify(body))
}

function post<T>(url: string, body: any, params?: any): Promise<HttpResponse<T>> {
  const method = 'POST'
  const request = new HttpRequest<T>({method, url})
  return request.send(JSON.stringify(body))
}

function put<T>(url: string, body: any, params?: any): Promise<HttpResponse<T>> {
  const method = 'PUT'
  const request = new HttpRequest<T>({method, url})
  return request.send(JSON.stringify(body))
}

export const data = {get, patch, post, put}
