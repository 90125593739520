import React, { ChangeEvent, FC, useRef, useEffect, useState } from "react";
import { classNames } from "../util/class-names";
import { FormDataValue } from "./FormContext";
import { useFormValueContext } from "./FormValueContext";

interface FormRadioProps {
  inline?: boolean;
  basevalue?: string;
  value: FormDataValue;
}

let uniqueId = 0;

export const FormRadio: FC<FormRadioProps> = ({
  children,
  inline,
  basevalue,
  value,
}) => {
  const [valuechecked, setValuechecked] = useState(false);
  const [current, setCurrent] = useFormValueContext();
  const id = useRef(`form-radio-${++uniqueId}`);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCurrent(value);
    }
  };

  useEffect(() => {
    setValuechecked(current === value);
    if (!current) {
      if (basevalue === value.toString()) {
        setCurrent(value);
      }
    }
  }, [basevalue, current, setCurrent, value]);

  const input = {
    checked: valuechecked, //current === value,
    className: "custom-control-input",
    id: id.current,
    onChange: handleChange,
  };

  return (
    <div
      className={classNames(
        "custom-control",
        "custom-radio",
        inline && "custom-control-inline"
      )}
    >
      <input type="radio" {...input} />
      {<span> </span>}
      <label className="custom-control-label" htmlFor={id.current}>
        {children}
      </label>
    </div>
  );
};
