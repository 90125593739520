import React, { FC, useCallback, useState } from "react";
import { BiCheckShield, BiArrowToLeft } from "react-icons/bi";
import { Link } from "react-router-dom";
import { data } from "shared/data";
import { Form } from "shared/form/Form";
import { FormGroup } from "shared/form/FormGroup";
import LoadingMask from "../components/LoadingMask";
import { FormInput } from "shared/form/FormInput";
import { FormSubmit } from "shared/form/FormSubmit";
import { Card } from "shared/layout/bootstrap/card/Card";
import { CardBody } from "shared/layout/bootstrap/card/CardBody";
import { PageLayout } from "shared/layout/pages/PageLayout";
import ToastAnimated, { showToast } from "../components/toast";
import styles from "./FistAcess.module.scss";

export const FistAcess: FC = () => {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (value) => {
    if (!value.email) {
      showToast("error", "Confira seu e-mail");
    } else
      try {
        setLoading(true);
        setEmail(value.email);
        data
          .post("/password/first", { email: value.email })
          .then(() => {
            setSent(true);
            setLoading(false);
          })
          .catch(() => {
            showToast("error", "Um erro ocorreu, tente novamente mais tarde.");
            setLoading(false);
          });
      } finally {
      }
  }, []);

  return (
    <div className={styles.root}>
      {loading ? <LoadingMask text="Processando..." /> : null}
      <PageLayout className={styles.page}>
        {sent ? (
          <>
            <div className={styles.send}>
              <div className={styles.BiCheckShield}>
                <BiCheckShield color="4ECE3D" size={80} />
              </div>

              <div className={styles.text}>
                Caso seu cadastro já tenha sido aprovado, enviaremos uma
                mensagem para <span>{email}</span> para definição de sua senha,
                por favor consulte sua caixa de e-mail.
              </div>
              <div className={styles.BiArrowToLeft}>
                <Link className="btn text-muted" to="/login">
                  <BiArrowToLeft color="C6D4D8" size={40} />
                  voltar
                </Link>
              </div>
            </div>
          </>
        ) : (
          <Card className={styles.card}>
            <CardBody>
              <h3 className="text-center">Primeiro acesso</h3>

              <Form onSubmit={handleSubmit}>
                <div className="mt-5 ">
                  <FormGroup label="E-mail">
                    <FormInput name="email" autoComplete="username" />
                  </FormGroup>
                </div>
                <FormSubmit className="mt-5 mb-3 text-center" block>
                  Solicitar
                </FormSubmit>

                <div className="text-center mt-5">
                  Acessar o Portal <br />
                  <Link className="btn text-muted" to="/login">
                    Clique aqui
                  </Link>
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
      </PageLayout>
      <div className={styles.quote}>
        <div className={styles.background}>
          <img alt="" src="/img/auth.jpg" />
        </div>
      </div>
      <ToastAnimated />
    </div>
  );
};
